import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TeachingLoadReports } from '../../../../components/EvaluateTeachingLoad/TeachingLoadReports';

export const PlannedReports = () => {
  const { t } = useTranslation();

  const tableData = useMemo(() => {
    return [
      { id: 'curriculum', name: t('common.notYet', { planned: t('pdf.plannedTeachingLoad') }) },
      { id: 'plan-single-teacher', name: t('pdf.plannedDetails') },
      { id: 'plan-overview', name: t('common.notYet', { planned: t('pdf.plannedOverview') }) },
      { id: 'plan-class', name: t('common.notYet', { planned: t('pdf.plannedClasses') }) },
      { id: 'plan-subject-group', name: t('common.notYet', { planned: t('pdf.plannedSubjectGroups') }) },
      { id: 'plan-key-figures', name: t('common.notYet', { planned: t('pdf.plannedKeyFigures') }) },
      { id: '', name: t('common.notYet', { planned: t('pdf.excelExport') }) },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TeachingLoadReports tableData={tableData} />;
};
