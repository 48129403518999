import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TimetableReports } from '../../../components/EvaluateTimetable/TimetableReports';

export const EvaluateByTeachers = () => {
  const { t } = useTranslation();

  const tableData = useMemo(() => {
    return [
      { id: 'single-teacher', name: t('pdf.singlePlans') },
      { id: 'all-teachers', name: t('pdf.overallPlans') },
      { id: 'epoch-teacher', name: t('pdf.epochPlans') },
      { id: '', name: t('common.notYet', { planned: t('pdf.annualPlans') }) },
      { id: '', name: t('common.notYet', { planned: t('pdf.annualOverviewPlans') }) },
      { id: '', name: t('common.notYet', { planned: t('pdf.annualCompactPlans') }) },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TimetableReports tableData={tableData} topic={'teachers'} />;
};
