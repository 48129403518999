import { AddIcon, Button, Select, SelectOptionType } from '@bp/ui-components';
import styles from './TimetablePrintConfigurationGridTile.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { TimetablePrintLayoutOptions, TimetablePrintLayoutPositions } from '../TimetablePrintConfiguration';

type TimetablePrintConfigurationGridTileProps = {
  value: TimetablePrintLayoutOptions | null;
  position: TimetablePrintLayoutPositions;
  options: SelectOptionType[];
  isMandatory?: boolean;
  onChange: (postion: TimetablePrintLayoutPositions, option: TimetablePrintLayoutOptions | null) => void;
};

export const TimetablePrintConfigurationGridTile = ({
  value,
  position,
  options,
  isMandatory,
  onChange,
}: TimetablePrintConfigurationGridTileProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles['timetable-print-configuration-grid-tile'], { [styles.mandatory]: isMandatory })}>
      {value === undefined ? (
        <Button
          icon={<AddIcon />}
          hierarchy='ghost'
          onClick={() => onChange(position, TimetablePrintLayoutOptions.empty)}
        >
          {t('timetable.configuration.addContent')}
        </Button>
      ) : (
        <Select
          isClearable={!isMandatory}
          options={options}
          defaultValue={options.find((o) => o.value === value)}
          onChange={(option) =>
            onChange(
              position,
              option !== null ? ((option as SelectOptionType).value as TimetablePrintLayoutOptions) : null,
            )
          }
          name={'config-selection'}
          menuPosition='fixed'
        />
      )}
    </div>
  );
};
