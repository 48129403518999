import { Suspense, useCallback, useContext, useMemo, useState } from 'react';
import {
  AddIcon,
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EditIcon,
  LazyLoader,
  Modal,
  Row,
  Table,
  TableColumns,
  Tooltip,
  useDefaultSelecting,
} from '@bp/ui-components';
import { useTranslation } from 'react-i18next';

import { useConfirm } from '../../../hooks/useConfirm';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { CurriculumForm } from '../Forms/CurriculumForm';
import { CardPrintInfo, CurriculaTableDataType } from '../types';
import { LessonCard } from '../../LessonCard/LessonCard';
import {
  showSuccessCreateToast,
  showSuccessDeleteToast,
  showSuccessDuplicateToast,
  showUserErrorToast,
} from '../../../utils/toast';
import { getTypeChecktArray, isSubject } from '../../../utils/typeguards';
import { useCurriculum } from '../hooks/useCurriculum';
import { CurriculumBlockContainerForm } from '../Forms/CurriculumBlockContainerForm';
import { CombinedError } from 'urql';
import { observer } from 'mobx-react-lite';
import { useGradeOptions } from '../../../hooks/useGradeOptions';
import { CurriculumContext } from '../../../pages/Deputate/Setup/Curricula/CurriculaPage';

export const CurriculumOverviewTable = observer(() => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const curriculumContext = useContext(CurriculumContext);

  const userConfigKey = 'curriculum-table';
  const { columnVisibility, saveColumnVisibility } = useHiddenColumns(userConfigKey, {
    'lesson.groups': true,
    classes: false,
    groupNames: false,
    'subjectHours.subjectHour_other': true,
    'common.studentHours': true,
    'common.teacherHours': true,
  });
  const { sorting, saveSorting } = useColumnsSort(userConfigKey);

  const gradeOptions = useGradeOptions();

  const { rowSelection, onRowSelectionChange } = useDefaultSelecting();

  const [currentCurriculumUuid, setCurrentCurriculumUuid] = useState<string | null>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [epochModalOpen, setEpochModalOpen] = useState<boolean>(false);

  const { confirm, ConfirmationDialog } = useConfirm();

  const {
    updateCheckboxes,
    deleteCurriculum,
    queryData,
    tableData,
    createLessonsFromCurriculum,
    loadingState,
    duplicateCurriculum,
    getClassesForSchoolyear,
    getQueryData,
    deleteLessons,
    isSingle,
  } = useCurriculum({
    versionUuid: curriculumContext.versionUuid,
    grade: curriculumContext.grade,
    subjectUuid: curriculumContext.subjectUuid,
  });

  const handleAddCurriculum = () => {
    setCurrentCurriculumUuid(null);
    setEditModalOpen(true);
  };

  const handleEditCurriculum = (uuid: string) => {
    const currentCurriculum = queryData?.curricula.find((curriculum) => curriculum.uuid === uuid);
    setCurrentCurriculumUuid(uuid);

    if (isSubject(currentCurriculum?.subject)) {
      // is a Lesson / Zuordnung
      setEditModalOpen(true);
    } else {
      // is EpochenPlan
      setEpochModalOpen(true);
    }
  };

  const handleClose = () => {
    setCurrentCurriculumUuid(null);
    setEditModalOpen(false);
  };

  const handleAddEpochClose = () => {
    setCurrentCurriculumUuid(null);
    setEpochModalOpen(false);
  };

  const handleDelete = async (curricula: CurriculaTableDataType[]) => {
    const confirmed = await confirm({ message: t('curriculum.confirm.delete.default_one') });
    if (confirmed) {
      const { error } = await deleteCurriculum(curricula);
      if (error) {
        showUserErrorToast({ error });
      } else {
        showSuccessDeleteToast(getTypeChecktArray<string>(curricula.map((c) => c.displayName)));
      }
    }
  };

  const handleDeleteLessons = async (curriculaUuids: string[]) => {
    const confirmed = await confirm({ message: t('lesson.confirm.delete.default', { count: 2 }) }); // fake multi ...
    if (confirmed) {
      const response = await deleteLessons(curriculaUuids);
      if (response.error) {
        showUserErrorToast({ error: response.error });
      } else {
        showSuccessDeleteToast();
      }
    }
  };

  const handleDuplicate = async (uuids: string[]) => {
    const res = await duplicateCurriculum(uuids);
    const resultsWithError = res.filter((r) => r.error);
    if (resultsWithError && resultsWithError.length > 0) {
      const error = resultsWithError[0].error as CombinedError;
      showUserErrorToast({ text: t('common.errorToastText'), error });
    } else {
      showSuccessDuplicateToast();
    }
  };

  const subjectOptions = useCreateSelectOptions(queryData?.subjects, 'uuid', 'name');

  const tableColumns = useMemo((): TableColumns<CurriculaTableDataType>[] => {
    return [
      {
        header: t('lesson.table.subject'),
        id: 'subject',
        meta: {
          filterName: t('lesson.table.subject'),
        },
        size: 250,
        cell: (cell) => {
          return (
            <Tooltip triggerClass='tooltip-in-table' content={cell.row.original.subject}>
              {cell.row.original.subject}
            </Tooltip>
          );
        },
        accessorFn: (cell) => cell.subject,
      },
      {
        header: t('common.type'),
        id: 'type',
        isMultiline: true,
        enableSorting: true,
        cell: ({ row }) => {
          if (row.original.isEpochPlan) {
            return <>{t('common.epochPlan')}</>;
          }
          const cardInfos = row.original.cardsInfos;
          return (
            <>
              {cardInfos && cardInfos.defaultCards.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 'var(--spacing-4)',
                    margin: 'var(--spacing-2) 0',
                  }}
                >
                  {cardInfos.defaultCards.map((group) => {
                    const card = group[0];
                    return (
                      <div key={card.uuid} title={card.typeLabel}>
                        {card.typeLabelShort}
                      </div>
                    );
                  })}
                </div>
              )}
              {cardInfos && cardInfos.subjectContainerCards.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 'var(--spacing-4)',
                    margin: 'var(--spacing-2) 0',
                  }}
                >
                  {cardInfos.subjectContainerCards.map((group) => {
                    const card = group[0];
                    return (
                      <div key={card.uuid} title={card.typeLabel}>
                        {card.typeLabelShort}
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          );
        },
        accessorFn: ({ cardsInfos, uuid }) => {
          const isEpochPlan = !isSubject(queryData?.curricula.find((l) => l.uuid === uuid)?.subject);
          const sum = (cardsInfos?.defaultCards.length ?? 0) + (cardsInfos?.subjectContainerCards.length ?? 0);
          let sortValue = sum > 0 ? `${sum}_` : `${sum}`;

          if (isEpochPlan) {
            sortValue += t('common.epochPlan');
          }
          if (cardsInfos && cardsInfos.defaultCards.length > 0) {
            sortValue += cardsInfos.defaultCards
              .map((dc) => {
                return dc.map((c) => c.typeLabelShort).join(',');
              })
              .join(', ');
          }
          if (cardsInfos && cardsInfos.subjectContainerCards.length > 0) {
            sortValue += cardsInfos.subjectContainerCards
              .map((dc) => {
                return dc.map((c) => c.typeLabelShort).join(',');
              })
              .join(', ');
          }
          return sortValue;
        },
      },
      {
        id: 'cards',
        isMultiline: true,
        enableSorting: false,
        canOverflow: true,
        enableColumnFilter: false,
        size: 200,
        header: t('curriculum.title.singular'),
        cell: ({ row }) => {
          const cardInfos = row.original.cardsInfos;
          return (
            <>
              {cardInfos && cardInfos.defaultCards.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 'var(--spacing-4)',
                    margin: 'var(--spacing-2) 0',
                  }}
                >
                  {cardInfos.defaultCards.map((group) => {
                    const card = group[0];
                    return (
                      <div key={card.uuid} title={card.cardLabel}>
                        <LessonCard
                          label={card.cardLabelShort}
                          lessonClasses={card.lesson}
                          count={card.counter}
                          teacherColors={card.colors}
                          duration={card.duration}
                          showHover={false}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {cardInfos && cardInfos.subjectContainerCards.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 'var(--spacing-4)',
                    margin: 'var(--spacing-2) 0',
                  }}
                >
                  {cardInfos.subjectContainerCards.map((group) => {
                    const card = group[0];
                    return (
                      <div key={card.uuid} title={card.cardLabel}>
                        <LessonCard
                          label={card.cardLabelShort}
                          lessonClasses={card.lesson}
                          count={card.counter}
                          teacherColors={card.colors}
                          duration={card.duration}
                          showHover={false}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          );
        },
        accessorFn: ({ cardsInfos }) => {
          const cardsToShow: CardPrintInfo[] = [];
          if (cardsInfos && cardsInfos.defaultCards.length > 0) {
            cardsInfos.defaultCards.map((group) => {
              cardsToShow.push({
                count: group[0].counter,
                duration: group[0].duration,
                hasSubjectContainer: false,
              });
            });
          }
          if (cardsInfos && cardsInfos.subjectContainerCards.length > 0) {
            cardsInfos.subjectContainerCards.map((group) => {
              cardsToShow.push({
                shortName: group[0].typeLabelShort,
                count: group[0].counter,
                duration: group[0].duration,
                hasSubjectContainer: true,
              });
            });
          }
          return cardsToShow
            .map((card) => {
              return `${card.shortName ? card.shortName + '\n' : ''}${card.count} x ${card.duration} ${card.hasSubjectContainer ? t('common.week', { count: card.duration }) : t('subjectHour.title.short')}`;
            })
            .join('\n\n');
        },
      },
      {
        header: t('lesson.table.classesGroups'),
        id: 'grades',
        cell: (cell) => {
          return (
            <Tooltip
              triggerClass='tooltip-in-table'
              content={
                cell.row.original.groupGradeNames
                  ?.sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
                  .join(' | ') ?? ''
              }
            >
              {cell.row.original.groupGradeNames
                ?.sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
                .join(' | ') ?? ''}
            </Tooltip>
          );
        },
        accessorFn: (data) =>
          data.groupGradeNames
            ?.sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
            .join(' | ') ?? '',
        meta: {
          filterName: t('classes.level.full'),
        },
        filterFn: 'arrIncludes',
        size: 250,
      },
      {
        header: t('persons.title.plural'),
        id: 'persons',
        accessorKey: 'curriculumInfo.teachersCount',
        alignment: 'right',
        size: 60,
      },
      {
        header: t('deputate.titleSingluar'),
        id: 'deputate.titleSingluar',
        accessorFn: (cell) => (cell.curriculumInfo?.deputat ?? 0).toFixed(2),
        cell: ({ row }) => (row.original.curriculumInfo?.deputat ?? 0).toFixed(2),
        alignment: 'right',
        size: 80,
      },
      {
        header: t('subjectHours.subjectHour_other'),
        id: 'subjectHours.subjectHour_other',
        accessorFn: (cell) => (cell.curriculumInfo?.studentCards ?? 0).toFixed(2),
        cell: ({ row }) => (row.original.curriculumInfo?.studentCards ?? 0).toFixed(2),
        alignment: 'right',
        size: 80,
      },
      {
        header: t('common.studentHours'),
        id: 'common.studentHours',
        accessorFn: (cell) => (cell.curriculumInfo?.studentHours ?? 0).toFixed(2),
        cell: ({ row }) => (row.original.curriculumInfo?.studentHours ?? 0).toFixed(2),
        alignment: 'right',
        size: 80,
      },
      {
        header: t('common.teacherHours'),
        id: 'common.teacherHours',
        accessorFn: (cell) => (cell.curriculumInfo?.teacherHours ?? 0).toFixed(2),
        cell: ({ row }) => (row.original.curriculumInfo?.teacherHours ?? 0).toFixed(2),
        alignment: 'right',
        size: 80,
      },
      {
        header: t('lesson.basics.isElective'),
        id: 'elective',
        accessorKey: 'elective',
        type: 'boolean',
        size: 60,
      },
      {
        header: t('lesson.basics.isTeachingLoadEnabled.short'),
        meta: {
          filterName: t('lesson.basics.isTeachingLoadEnabled.full'),
          tooltip: t('lesson.basics.isTeachingLoadEnabled.full'),
        },
        id: 'teachingLoadEnabled',
        accessorKey: 'teachingLoadEnabled',
        type: 'boolean',
        size: 60,
      },
      {
        header: t('lesson.basics.isTimetableEnabled.short'),
        meta: {
          filterName: t('lesson.basics.isTimetableEnabled.full'),
          tooltip: t('lesson.basics.isTimetableEnabled.full'),
        },
        id: 'timetableEnabled',
        accessorKey: 'timetableEnabled',
        type: 'boolean',
        size: 60,
      },
      {
        header: schoolYear?.name ?? '',
        meta: {
          filterName: t('lesson.allLessonsInSchoolYear'),
          tooltip: t('lesson.hasLessonInSchoolYear.full', { name: schoolYear?.name }),
        },
        type: 'string',
        id: 'lessonsInSchoolYear',
        accessorFn: (row) => {
          let count: number;
          const classes = getClassesForSchoolyear(row.uuid);
          if (isSingle(row.uuid)) {
            count = getClassesForSchoolyear(row.uuid).length;
          } else {
            count = classes.length > 0 ? 1 : 0;
          }

          return `${row.lessonsInSchoolYear} ${t('common.fromCount', { count })}`;
        },
        size: 80,
        cell: ({ row }) => {
          let count: number;
          const classes = getClassesForSchoolyear(row.original.uuid);
          if (isSingle(row.original.uuid)) {
            count = getClassesForSchoolyear(row.original.uuid).length;
          } else {
            count = classes.length > 0 ? 1 : 0;
          }

          return `${row.original.lessonsInSchoolYear} ${t('common.fromCount', { count })}`;
        },
      },
    ];
  }, [subjectOptions, gradeOptions, schoolYear, queryData, getClassesForSchoolyear, isSingle]);

  const dropdownItems = (row: Row<CurriculaTableDataType>): DropdownMenuItem[] => {
    const uuid = row.original.uuid;

    const classes = getClassesForSchoolyear(row.original.uuid);
    const lessonsInSchoolYear = row.original.lessonsInSchoolYear;

    const canImport = lessonsInSchoolYear === 0 && classes.length > 0;
    const curriculum = getQueryData(row.original.uuid);
    const lessonsToDelete = curriculum?.lessons
      .filter((l) => l.schoolYear.uuid === schoolYear?.uuid)
      .map((l) => l.uuid);

    return [
      {
        label: t('lesson.actions.toggle.isElective'),
        type: 'switch',
        value: row.original.elective ?? false,
        onValueChange: async () => {
          await updateCheckboxes({
            type: 'elective',
            uuids: [uuid],
            value: !row.original.elective,
          });
        },
      },
      {
        label: t('lesson.basics.isTeachingLoadEnabled.full'),
        type: 'switch',
        value: row.original.teachingLoadEnabled ?? false,
        onValueChange: async () => {
          await updateCheckboxes({
            type: 'teachingLoadEnabled',
            uuids: [uuid],
            value: !row.original.teachingLoadEnabled,
          });
        },
      },
      {
        label: t('lesson.basics.isTimetableEnabled.full'),
        type: 'switch',
        value: row.original.timetableEnabled ?? false,

        onValueChange: async () => {
          await updateCheckboxes({
            type: 'timetableEnabled',
            uuids: [uuid],
            value: !row.original.timetableEnabled,
          });
        },
      },
      {
        type: 'ruler',
      },
      {
        label: t('lesson.actions.create.lessonsFormCurricula', { count: 1 }),
        type: 'default',
        disabled: !canImport,
        onClick: async () => {
          if (!canImport) {
            return;
          }
          const success = await createLessonsFromCurriculum({
            uuids: [uuid],
          });
          if (success) {
            showSuccessCreateToast();
          } else {
            // todo
            showUserErrorToast({
              error: {
                message: '',
                name: '',
                graphQLErrors: [],
              },
            });
          }
        },
      },
      {
        label: t('lesson.actions.delete.lessonsForCurricula', { count: 1 }),
        type: 'default',
        disabled: !lessonsToDelete || lessonsToDelete.length === 0,
        onClick: async () => {
          if (lessonsToDelete) {
            await handleDeleteLessons([row.original.uuid]);
          }
        },
      },
      {
        type: 'ruler',
      },
      {
        label: t('common.duplicating'),
        type: 'default',
        onClick: () => {
          handleDuplicate([uuid]);
        },
      },
      {
        type: 'ruler',
      },
      {
        label: t('common.delete'),
        type: 'error',
        onClick: () => {
          handleDelete([row.original]);
        },
      },
    ];
  };

  const addDropdownItems: DropdownMenuItem[] = [
    {
      label: t('common.epochPlan'),
      onClick: () => handleEditCurriculum(currentCurriculumUuid ?? ''),
    },
    {
      label: t('curriculum.title.singular'),
      onClick: handleAddCurriculum,
    },
  ];

  const bulkEdit = useCallback(
    (selectedFlatRows: Row<CurriculaTableDataType>[]) => {
      return (
        <DropdownMenu
          data={[
            {
              label: t('lesson.basics.isElective'),
              type: 'default',
              subContent: [
                {
                  label: t('common.activateAll'),
                  onClick: async () => {
                    await updateCheckboxes({
                      type: 'elective',
                      uuids: selectedFlatRows.map(({ original }) => original.uuid),
                      value: true,
                    });
                  },
                },
                {
                  label: t('common.deactivateAll'),
                  onClick: async () => {
                    await updateCheckboxes({
                      type: 'elective',
                      uuids: selectedFlatRows.map(({ original }) => original.uuid),
                      value: false,
                    });
                  },
                },
              ],
            },
            {
              label: t('lesson.basics.isTimetableEnabled.full'),
              type: 'default',
              subContent: [
                {
                  label: t('common.activateAll'),
                  onClick: async () => {
                    await updateCheckboxes({
                      type: 'timetableEnabled',
                      uuids: selectedFlatRows.map(({ original }) => original.uuid),
                      value: true,
                    });
                  },
                },
                {
                  label: t('common.deactivateAll'),
                  onClick: async () => {
                    await updateCheckboxes({
                      type: 'timetableEnabled',
                      uuids: selectedFlatRows.map(({ original }) => original.uuid),
                      value: false,
                    });
                  },
                },
              ],
            },
            {
              label: t('lesson.basics.isTeachingLoadEnabled.full'),
              type: 'default',
              subContent: [
                {
                  label: t('common.activateAll'),
                  onClick: async () => {
                    await updateCheckboxes({
                      type: 'teachingLoadEnabled',
                      uuids: selectedFlatRows.map(({ original }) => original.uuid),
                      value: true,
                    });
                  },
                },
                {
                  label: t('common.deactivateAll'),
                  onClick: async () => {
                    await updateCheckboxes({
                      type: 'teachingLoadEnabled',
                      uuids: selectedFlatRows.map(({ original }) => original.uuid),
                      value: false,
                    });
                  },
                },
              ],
            },
            { type: 'ruler' },
            {
              label: t('lesson.actions.create.lessonsFormCurricula', { count: selectedFlatRows.length, max: 30 }),
              type: 'default',
              disabled: selectedFlatRows.length > 30,
              onClick: async () => {
                const success = await createLessonsFromCurriculum({
                  uuids: selectedFlatRows.map(({ original }) => original.uuid),
                });
                if (success) {
                  showSuccessCreateToast();
                } else {
                  showUserErrorToast({
                    error: {
                      message: '',
                      name: '',
                      graphQLErrors: [],
                    },
                  });
                }
              },
            },
            {
              label: t('lesson.actions.delete.lessonsForCurricula', { count: selectedFlatRows.length, max: 30 }),
              type: 'default',
              disabled: selectedFlatRows.length > 30,
              onClick: async () => {
                await handleDeleteLessons(selectedFlatRows.map((s) => s.original.uuid));
              },
            },
            { type: 'ruler' },
            {
              label: t('common.duplicating'),
              type: 'default',
              disabled: selectedFlatRows.length > 30,
              onClick: async () => {
                const uuids = selectedFlatRows.map(({ original }) => original.uuid);
                await handleDuplicate(uuids);
              },
            },
            { type: 'ruler' },
            {
              label: t('common.delete'),
              type: 'error',
              onClick: async () => {
                await handleDelete(selectedFlatRows.map(({ original }) => original));
              },
            },
          ]}
        />
      );
    },
    [createLessonsFromCurriculum, handleDelete, handleDeleteLessons, handleDuplicate, updateCheckboxes],
  );

  return (
    <>
      <Table<CurriculaTableDataType>
        data={tableData}
        showBorderRadius
        showShadow
        canScroll
        minHeight={1000}
        showVisibility
        isOnWhite={false}
        showActionBar
        rowSelection={rowSelection}
        onRowSelectionChange={onRowSelectionChange}
        showSelect={true}
        showSort={true}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={saveColumnVisibility}
        sorting={sorting}
        onSortingChange={saveSorting}
        onAddClick={handleAddCurriculum}
        bulkEditDropdownContent={bulkEdit}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('curriculum.title.plural')} - ${t('common.schoolYear')}`,
          filename: `${t('curriculum.title.plural')}_${schoolYear?.shortName}`,
        }}
        actionBarSettings={{
          showSelectedRowsCount: true,
          showExpertFilter: true,
          showPrintButton: true,
          showBulkEdit: true,
          showAddButton: false,
          extendedActionsRight: (
            <Dropdown
              noPadding
              trigger={
                <Button hierarchy='tertiary' icon={<AddIcon className={'svg-icon'} />}>
                  {t('common.add')}
                </Button>
              }
            >
              <DropdownMenu data={addDropdownItems} />
            </Dropdown>
          ),
        }}
        columns={tableColumns}
        lastColWidth='80px'
        lastCol={(row) => {
          return (
            <ButtonGroup>
              <Button
                isLoading={loadingState.some((ls) => ls.uuid === row.original.uuid && ls.loading)}
                hierarchy='secondary'
                type='button'
                onClick={() => {
                  setCurrentCurriculumUuid(row.original.uuid);
                  handleEditCurriculum(row.original.uuid);
                }}
                icon={<EditIcon className='small' />}
              />
              <Dropdown
                disabled={loadingState.some((ls) => ls.uuid === row.original.uuid && ls.loading)}
                trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />}
              >
                <DropdownMenu data={dropdownItems(row)} />
              </Dropdown>
            </ButtonGroup>
          );
        }}
      />
      <Modal
        isOpen={editModalOpen}
        onRequestClose={handleClose}
        title={currentCurriculumUuid ? t('curriculum.edit') : t('curriculum.add')}
      >
        {editModalOpen && curriculumContext.versionUuid && (
          <Suspense fallback={<LazyLoader />}>
            <CurriculumForm
              versionUuid={curriculumContext.versionUuid}
              closeForm={handleClose}
              curriculum={queryData?.curricula.find((c) => c.uuid === currentCurriculumUuid)}
              defaultInitialValues={{
                curriculumVersionUuid: curriculumContext.versionUuid,
                subject: curriculumContext.subject,
                timetableEnabled: true,
                teachingLoadEnabled: true,
              }}
            />
          </Suspense>
        )}
      </Modal>
      {/*epoch */}
      <Modal isOpen={epochModalOpen} onRequestClose={() => handleAddEpochClose()} title={t('common.epochPlan')}>
        {epochModalOpen && curriculumContext.versionUuid && (
          <Suspense fallback={<LazyLoader />}>
            <CurriculumBlockContainerForm
              closeForm={() => handleAddEpochClose()}
              defaultInitialValues={{ curriculumVersionUuid: curriculumContext.versionUuid }}
              curriculumUuid={currentCurriculumUuid}
              versionUuid={curriculumContext.versionUuid}
            />
          </Suspense>
        )}
      </Modal>
      <ConfirmationDialog />
    </>
  );
});
