import { useNavigate, useParams } from 'react-router-dom';
import { CurrentDeputatesTable } from '../../../../components/Deputate/Tables/CurrentDeputatesTable';
import { FC } from 'react';
import { t } from 'i18next';

type Props = { slug: string };
export const EvaluateCurrentOverview: FC<Props> = ({ slug }) => {
  const navigate = useNavigate();

  const { blockUuid } = useParams();

  const handleRowClick = (data: string) =>
    navigate(slug + '/' + blockUuid + '/' + t('routes.lessons.plan.teachers.slug') + '/' + data);

  return <CurrentDeputatesTable handleRowClick={handleRowClick} timetableBlockUuid={blockUuid ?? ''} />;
};
