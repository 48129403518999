import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EditIcon,
  Modal,
  Row,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useColumnsSort } from '../../hooks/useColumnsSort';
import { useHiddenColumns } from '../../hooks/useHiddenColumns';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { TimetableType } from './graphql';
import dayjs from 'dayjs';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { TimetableVersionsOverviewModal } from '../TimetableVersion/TimetableVersionsOverview/TimetableVersionsOverviewModal';
import { useDeleteTimetableMutation, useTimetableQuery } from '../../types/planung-graphql-client-defs';
import TimetableForm from './Forms/TimetableForm';
import { useConfirm } from '../../hooks/useConfirm';
import { showSuccessDeleteToast, showUserErrorToast } from '../../utils/toast';
import { useTimetableBlock } from '../TimetableBlock/useTimetableBlock';

export const TimetableTable = observer(() => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('timetable-list', {
    wasActive: false,
    status: false,
  });
  const { sorting, saveSorting } = useColumnsSort('timetable-list');

  const { confirm, ConfirmationDialog } = useConfirm();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [timetable, setTimetable] = useState<TimetableType | null>(null);
  const [showVersionModal, setShowVersionModal] = useState(false);

  const context = useMemorizedCacheTag('TIMETABLE_VERSIONS');
  const [{ data }] = useTimetableQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: schoolYear?.uuid,
        },
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context,
  });

  const [, deleteDraft] = useDeleteTimetableMutation();

  const { blocks } = useTimetableBlock();

  function createColumns(): TableColumns<TimetableType>[] {
    return [
      {
        header: t('common.name'),
        id: 'name',
        accessorKey: 'name',
        size: 250,
      },
      {
        header: t('timetableDraft.versionCount'),
        id: 'versions',
        accessorFn(row) {
          return row.versions.length.toString();
        },
        cell({ row }) {
          return row.original.versions.length;
        },
        size: 50,
      },
      {
        header: t('common.createdAt'),
        id: 'createdAt',
        accessorKey: 'createdAt',
        type: 'date',
        cell({ row }) {
          return row.original.createdAt ? dayjs(row.original.createdAt).format('DD.MM.YYYY - HH:mm') : '';
        },
        size: 150,
      },
      {
        header: t('common.updatedAt'),
        id: 'updatedAt',
        accessorKey: 'updatedAt',
        type: 'date',
        cell({ row }) {
          return row.original.updatedAt ? dayjs(row.original.updatedAt).format('DD.MM.YYYY - HH:mm') : '';
        },
        size: 150,
      },
      {
        header: t('common.comment'),
        id: 'comment',
        accessorKey: 'comment',
        size: 150,
      },
      {
        header: t('common.status'),
        id: 'status',
        accessorKey: 'status',
      },
      {
        header: t('timetableDraft.wasActive'),
        id: 'wasActive',
        accessorKey: 'wasActive',
        type: 'boolean',
        size: 100,
      },
      {
        header: t('timetableDraft.isActive'),
        id: 'active',
        type: 'boolean',
        accessorKey: 'active',
        size: 80,
      },
    ];
  }

  const tableColumns = useMemo(createColumns, []);

  const memoizedData = useMemo((): TimetableType[] => {
    return data && data
      ? data.timetables.map((t) => ({
          ...t,
          active: !t.draft,
        }))
      : [];
  }, [blocks, data]);

  const handleDelete = async (uuids: string[]) => {
    const tt = data?.timetables.filter((t) => uuids.includes(t.uuid)).map((tt) => tt.name);

    await confirm({
      onConfirm: async () => {
        const resp = await deleteDraft(
          {
            uuids: uuids,
          },
          context,
        );
        if (resp.error) {
          showUserErrorToast({ error: resp.error });
        }
        if (!resp.error) showSuccessDeleteToast();
      },
      message: t('timetableDraft.deleteConfirm', {
        timetables: tt?.join(', '),
        count: tt?.length,
      }),
    });
  };

  const handleEdit = (draftRow: Row<TimetableType>) => {
    setTimetable(draftRow.original);
    setIsModalOpen(true);
  };

  function closeModal() {
    setTimetable(null);
    setIsModalOpen(false);
  }

  function handleShowVersionModal(timetable: TimetableType) {
    setTimetable(timetable);
    setShowVersionModal(true);
  }

  function handleHideVersionModal() {
    setTimetable(null);
    setShowVersionModal(false);
  }

  const createActionItems = useCallback(
    (row: Row<TimetableType>): DropdownMenuItem[] => {
      return [
        {
          label: t('common.delete'),
          type: 'error',
          onClick: async () => {
            await handleDelete([row.original.uuid]);
          },
          disabled: row.original.active,
        },
      ];
    },
    [handleDelete],
  );

  return (
    <>
      <Table<TimetableType>
        showBorderRadius
        showShadow
        canScroll
        minHeight={1100}
        breakpoint={null}
        showVisibility
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={saveColumnVisibility}
        sorting={sorting}
        onSortingChange={saveSorting}
        columns={tableColumns}
        data={memoizedData}
        showActionBar
        actionBarSettings={{ showAddButton: true }}
        isOnWhite={false}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('timetableDraft.title.plural')} - ${t('common.schoolYear')} ${schoolYear?.shortName}`,
          filename: `${t('timetableDraft.title.plural')}_${schoolYear?.shortName}`,
        }}
        onAddClick={() => {
          setIsModalOpen(true);
        }}
        lastColWidth='160px'
        lastCol={(row) => {
          return (
            <ButtonGroup className='ml-4'>
              <Button hierarchy='secondary' onClick={() => handleShowVersionModal(row.original)}>
                {t('pinboard.versions')}
              </Button>
              <Button
                hierarchy={'secondary'}
                onClick={() => {
                  handleEdit(row);
                }}
                icon={<EditIcon className='small' />}
                disabled={row.original.active}
              />
              <Dropdown
                noPadding
                trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />}
              >
                <DropdownMenu data={createActionItems(row)} />
              </Dropdown>
            </ButtonGroup>
          );
        }}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        title={timetable ? t('timetableDraft.edit') : t('timetableDraft.add')}
      >
        <TimetableForm timetable={timetable} selectAllClasses onClose={closeModal} />
      </Modal>
      <TimetableVersionsOverviewModal
        timetable={timetable}
        draftName={memoizedData.find((tt) => tt.uuid === timetable?.uuid)?.name ?? ''}
        isOpen={showVersionModal && !!timetable}
        onClose={() => handleHideVersionModal()}
      />

      <ConfirmationDialog />
    </>
  );
});
