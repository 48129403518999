import { Grid, GridColumn, GridRow, SelectOptionType } from '@bp/ui-components';
import styles from './TimetablePrintConfigurationGrid.module.scss';
import { TimetablePrintConfigurationGridTile } from '../TimetablePrintConfigurationGridTile/TimetablePrintConfigurationGridTile';
import { useTranslation } from 'react-i18next';
import {
  TimetablePrintLayoutPositions,
  TimetablePrintLayoutOptions,
  TimetablePrintColorPositions,
  TimetablePrintLayoutData,
} from '../TimetablePrintConfiguration';

type TimetablePrintConfigurationGridProps = {
  data: TimetablePrintLayoutData;
  color: string;
  colorPosition: TimetablePrintColorPositions;
  onLayoutChange: (position: TimetablePrintLayoutPositions, option: TimetablePrintLayoutOptions | null) => void;
};

export const TimetablePrintConfigurationGrid = ({
  data,
  color,
  colorPosition,
  onLayoutChange,
}: TimetablePrintConfigurationGridProps) => {
  const { t } = useTranslation();
  const white = '#f7f7f7';

  const background =
    colorPosition === TimetablePrintColorPositions.top
      ? `linear-gradient(0deg, ${white} 90%, ${color} 10%)`
      : colorPosition === TimetablePrintColorPositions.right
        ? `linear-gradient(90deg, ${white} 95%, ${color} 5%)`
        : colorPosition === TimetablePrintColorPositions.bottom
          ? `linear-gradient(180deg, ${white} 90%, ${color} 10%)`
          : colorPosition === TimetablePrintColorPositions.left
            ? `linear-gradient(270deg, ${white} 95%, ${color} 5%)`
            : color;

  const configOptions: SelectOptionType[] = [
    {
      label: t('timetable.configuration.options.subjectShort'),
      value: TimetablePrintLayoutOptions.subjectShort,
    },
    {
      label: t('timetable.configuration.options.subjectLong'),
      value: TimetablePrintLayoutOptions.subjectLong,
    },
    {
      label: t('classGroups.group'),
      value: TimetablePrintLayoutOptions.groupName,
    },
    {
      label: t('classes.title.singular'),
      value: TimetablePrintLayoutOptions.className,
    },
    {
      label: `${t('classes.title.singular')}/${t('classGroups.group')}`,
      value: TimetablePrintLayoutOptions.classAndGroup,
    },
    {
      label: t('timetable.configuration.options.teacherShort'),
      value: TimetablePrintLayoutOptions.teacherShort,
    },
    {
      label: t('timetable.configuration.options.teacherLong'),
      value: TimetablePrintLayoutOptions.teacherLong,
    },
    {
      label: t('rooms.title.singular'),
      value: TimetablePrintLayoutOptions.roomName,
    },
    {
      label: t('rooms.roomNumber'),
      value: TimetablePrintLayoutOptions.roomNumber,
    },
    {
      label: `${t('timetable.configuration.options.teacher')}/${t('rooms.title.singular')}`,
      value: TimetablePrintLayoutOptions.teacherAndRoom,
    },
  ];

  return (
    <Grid className={styles['timetable-print-configuration-grid']} useFormGap>
      <GridRow>
        <GridColumn width={9}>
          <div className={styles.config}>
            <div className={styles.top}>
              <TimetablePrintConfigurationGridTile
                position={TimetablePrintLayoutPositions.topLeft}
                value={data[TimetablePrintLayoutPositions.topLeft].option}
                options={configOptions}
                onChange={onLayoutChange}
              />
              <TimetablePrintConfigurationGridTile
                position={TimetablePrintLayoutPositions.topCenter}
                value={data[TimetablePrintLayoutPositions.topCenter].option}
                options={configOptions}
                onChange={onLayoutChange}
              />
              <TimetablePrintConfigurationGridTile
                position={TimetablePrintLayoutPositions.topRight}
                value={data[TimetablePrintLayoutPositions.topRight].option}
                options={configOptions}
                onChange={onLayoutChange}
              />
            </div>
            <div className={styles.center}>
              <TimetablePrintConfigurationGridTile
                isMandatory
                position={TimetablePrintLayoutPositions.center}
                value={data[TimetablePrintLayoutPositions.center].option}
                options={configOptions}
                onChange={onLayoutChange}
              />
            </div>
            <div className={styles.bottom}>
              <TimetablePrintConfigurationGridTile
                position={TimetablePrintLayoutPositions.bottomLeft}
                value={data[TimetablePrintLayoutPositions.bottomLeft].option}
                options={configOptions}
                onChange={onLayoutChange}
              />
              <TimetablePrintConfigurationGridTile
                position={TimetablePrintLayoutPositions.bottomCenter}
                value={data[TimetablePrintLayoutPositions.bottomCenter].option}
                options={configOptions}
                onChange={onLayoutChange}
              />
              <TimetablePrintConfigurationGridTile
                position={TimetablePrintLayoutPositions.bottomRight}
                value={data[TimetablePrintLayoutPositions.bottomRight].option}
                options={configOptions}
                onChange={onLayoutChange}
              />
            </div>
          </div>
        </GridColumn>

        <GridColumn width={3}>
          <div
            className={styles.preview}
            style={{ background: colorPosition === TimetablePrintColorPositions.full ? color : background }}
          >
            <div className={styles.top}>
              <div>{data[TimetablePrintLayoutPositions.topLeft].preview}</div>
              <div>{data[TimetablePrintLayoutPositions.topCenter].preview}</div>
              <div>{data[TimetablePrintLayoutPositions.topRight].preview}</div>
            </div>
            <div className={styles.center}>
              <div>{data[TimetablePrintLayoutPositions.center].preview}</div>
            </div>
            <div className={styles.bottom}>
              <div>{data[TimetablePrintLayoutPositions.bottomLeft].preview}</div>
              <div>{data[TimetablePrintLayoutPositions.bottomCenter].preview}</div>
              <div>{data[TimetablePrintLayoutPositions.bottomRight].preview}</div>
            </div>
          </div>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
