import { Modal, ModalBottomButtons } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import {
  TimetablePrintBaseData,
  TimetablePrintColorPositions,
  TimetablePrintConfiguration,
  TimetablePrintLayoutData,
  TimetablePrintLayoutOptions,
  TimetablePrintLayoutPositions,
  TimetablePrintConfigurationOptions,
} from '../TimetablePrintConfiguration';
import { useState } from 'react';

type TimetablePrintConfigurationModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const TimetablePrintConfigurationModal = ({ isOpen, onClose }: TimetablePrintConfigurationModalProps) => {
  const { t } = useTranslation();
  const subjectColor = '#C0CEF2';

  const initialBaseData: TimetablePrintBaseData = {
    color: subjectColor,
    position: TimetablePrintColorPositions.full,
    config: {
      [TimetablePrintConfigurationOptions.printClasses]: false,
      [TimetablePrintConfigurationOptions.scaleText]: false,
      [TimetablePrintConfigurationOptions.exactMinute]: false,
      [TimetablePrintConfigurationOptions.printSubtitle]: false,
      [TimetablePrintConfigurationOptions.printLogo]: false,
    },
  };

  const [baseData, setBaseData] = useState<TimetablePrintBaseData>(initialBaseData);

  const inintialLayoutData: TimetablePrintLayoutData = {
    [TimetablePrintLayoutPositions.topLeft]: { option: null, preview: '' },
    [TimetablePrintLayoutPositions.topCenter]: { option: null, preview: '' },
    [TimetablePrintLayoutPositions.topRight]: { option: null, preview: '' },
    [TimetablePrintLayoutPositions.center]: {
      option: TimetablePrintLayoutOptions.subjectLong,
      preview: t('timetable.configuration.preview.subjectLong'),
    },
    [TimetablePrintLayoutPositions.bottomLeft]: { option: null, preview: '' },
    [TimetablePrintLayoutPositions.bottomCenter]: { option: null, preview: '' },
    [TimetablePrintLayoutPositions.bottomRight]: { option: null, preview: '' },
  };

  const [layoutData, setLayoutData] = useState<TimetablePrintLayoutData>(inintialLayoutData);

  function handleSubmit() {
    // TODO: implement
    console.log({ ...baseData, ...layoutData });
  }

  const restoreDefaults = () => {
    setBaseData(initialBaseData);
    setLayoutData(inintialLayoutData);
  };

  return (
    <Modal
      title={t('timetable.configuration.configure')}
      isOpen={isOpen}
      onRequestClose={() => {
        restoreDefaults();
        onClose();
      }}
    >
      <TimetablePrintConfiguration
        onBaseDataChange={(data) => {
          setBaseData({ ...data });
        }}
        baseData={baseData}
        layoutData={layoutData}
        onLayoutChange={(data) => {
          setLayoutData({ ...data });
        }}
      />
      <ModalBottomButtons
        closeButton={{
          callback: () => {
            restoreDefaults();
            onClose();
          },
        }}
        submitButton={{
          text: t('common.save'),
          callback: () => handleSubmit(),
        }}
      />
    </Modal>
  );
};
