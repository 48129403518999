import { AddIcon, Button, Modal } from '@bp/ui-components';
import { FC, useState } from 'react';
import { TimetableVersionsOverview } from './TimetableVersionsOverview';
import { useTranslation } from 'react-i18next';
import styles from './TimetableVersionsOverview.module.scss';
import { useCreateTimetableVersionMutation } from '../../../types/planung-graphql-client-defs';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { TimetableType } from '../../Timetable/graphql';

type TimetableVersionsOverviewModalProps = {
  timetable: TimetableType | null;
  draftName: string;
  isOpen: boolean;
  onClose: () => void;
};

export const TimetableVersionsOverviewModal: FC<TimetableVersionsOverviewModalProps> = ({
  timetable,
  draftName,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const context = useMemorizedCacheTag('TIMETABLE_VERSIONS');

  const [loading, setLoading] = useState<boolean>(false);
  const [, createVersion] = useCreateTimetableVersionMutation();

  async function onAdd() {
    if (timetable) {
      setLoading(true);
      await createVersion(
        {
          timetableUuid: timetable.uuid,
          organizationUuid: pimAuthClaims.getOrganizationUuid(),
        },
        context,
      );
      setLoading(false);
    }
  }

  return (
    <Modal
      className={styles['timetable-versions-overview-modal']}
      title={t('timetableVersion.showForDraft', { name: draftName })}
      isOpen={isOpen}
      onRequestClose={onClose}
      loading={loading}
      actions={
        <Button onClick={onAdd} hierarchy='tertiary' icon={<AddIcon />}>
          {t('timetableVersion.add')}
        </Button>
      }
    >
      <TimetableVersionsOverview timetable={timetable} onNavigate={() => setLoading(true)} />
    </Modal>
  );
};
