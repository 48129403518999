import { useColumnsSort } from '../../../hooks/useColumnsSort';
import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  Row,
  Table,
  useDefaultSelecting,
} from '@bp/ui-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createTimetableVersionTableColumns } from '../utils/TimetableVersionTableUtils';
import { TimetableVersionTableType } from '../graphql/types';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import { observer } from 'mobx-react-lite';
import { useConfirm } from '../../../hooks/useConfirm';
import { useTimetableStore } from '../../TimetableGrid/TimetableProvider';

type TimetableVersionRoomsTableProps = {
  versionUuid: string;
  onRowSelection: (rowData: TimetableVersionTableType, index: number) => void;
  onShowLessonClick: (uuid: string) => void;
  versionRoomUuids: string[];
  onRemove: (uuids: string[]) => void;
};

export const TimetableVersionRoomsTableTable = observer(
  ({ onRowSelection, onShowLessonClick, versionRoomUuids, versionUuid, onRemove }: TimetableVersionRoomsTableProps) => {
    const { t } = useTranslation();

    const { sorting, saveSorting } = useColumnsSort('timetableVersionRoomsTable');
    const { rowSelection, onRowSelectionChange } = useDefaultSelecting({ 0: true });

    const { roomsData } = useLoadBasicData({ pause: !versionUuid });

    const rooms: TimetableVersionTableType[] = useMemo(() => {
      return (
        roomsData?.rooms
          .filter((r) => versionRoomUuids.includes(r.uuid))
          .map((r) => {
            return {
              color: {},
              count: 0,
              name: r.name,
              shortName: r.roomNumber ?? '',
              uuid: r.uuid,
            };
          }) ?? []
      );
    }, [roomsData, versionRoomUuids]);

    const columns = useMemo(() => createTimetableVersionTableColumns(t('common.name') as string), [t]);

    const store = useTimetableStore();

    const canBeRemoved = (uuids: string[]) => {
      return uuids.every((uuid) => {
        let roomUsed = false;
        store.placedCardsForRoomsCell.forEach((r, key) => {
          if (key.includes(uuid)) {
            roomUsed = true;
          }
        });
        return !roomUsed;
      });
    };

    const { confirm, ConfirmationDialog } = useConfirm();

    const handleRemove = async (uuids: string[]) => {
      const res = await confirm({
        title: t('timetableVersion.removeRooms', { count: uuids.length }),
        message: t('timetableVersion.removeRoomsDescription', { count: uuids.length }),
      });
      if (res) {
        onRemove(uuids);
      }
    };
    const readOnly = store.readonly;
    return (
      <>
        <Table<TimetableVersionTableType>
          showBorderRadius
          showShadow
          canScroll
          minHeight={900}
          sorting={sorting}
          onSortingChange={saveSorting}
          columns={columns}
          data={rooms}
          lastColWidth='140px'
          lastCol={(row: Row<TimetableVersionTableType>) => {
            return (
              <ButtonGroup>
                <Button hierarchy='secondary' onClick={() => onShowLessonClick(row.original.uuid)}>
                  {t('timetableVersion.showLessons')}
                </Button>
                <Dropdown
                  noPadding
                  trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />}
                >
                  <DropdownMenu
                    data={[
                      {
                        label: t('timetableVersion.editData'),
                        disabled: true,
                        onClick: () => {
                          console.log('edit clicked');
                        },
                      },
                      {
                        label: t('timetableVersion.test'),
                      },
                      {
                        label: t('timetableVersion.remove'),
                        type: 'error',
                        disabled: !canBeRemoved([row.original.uuid]) || readOnly,
                        onClick: async () => {
                          await handleRemove([row.original.uuid]);
                        },
                      },
                    ]}
                  />
                </Dropdown>
              </ButtonGroup>
            );
          }}
          showRowHover
          onRowClick={(event, row) => {
            if (onRowSelectionChange) onRowSelectionChange({ [row.id]: true });
            onRowSelection(row.original, row.index);
            document.querySelector('#content')?.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          onRowSelectionChange={onRowSelectionChange}
          rowSelection={rowSelection}
          showSelected
        />
        <ConfirmationDialog />
      </>
    );
  },
);
