import { showSuccessCreateToast } from './toast';
import { ReportSubType, ReportType } from '../components/EvaluateTimetable/types';
import { apiHelper } from './apiHelper';
import { ReportPdfTableData } from '../components/EvaluateTimetable/TimetableReportsTable';
import dayjs from 'dayjs';

export const downloadReport = async (report: ReportPdfTableData) => {
  await apiHelper<void>({
    method: 'GET',
    path: `/pdf/${report.uuid}`,
    type: 'download',
    downloadFileName: report.name.replace(/ /g, '_') + '_' + report.timestamp.format('YYYY-MM-DD') + '.pdf',
  });
};

export const deleteReport = async (report: { uuid: string; name: string }) => {
  await apiHelper<void>({
    method: 'DELETE',
    path: `/pdf/${report.uuid}`,
  });
};

export const downloadDebug = async (uuid: string, type: ReportType) => {
  await apiHelper<void>({
    method: 'GET',
    path: `/pdf/export/${type}/${uuid}`,
    type: 'download',
    downloadFileName: type + '_' + uuid + '_' + dayjs().format('YYYY-MM-DD') + '.json',
  });
};

export const createTimetableCollectiveReport = async (
  uuid: string,
  type: ReportType,
  subType: ReportSubType | undefined,
) => {
  const response = await apiHelper<Response>({
    method: 'POST',
    path: `/pdf/${type}/${uuid}/${subType}`,
  });

  if (response.ok) {
    showSuccessCreateToast();
  }
};

export const createTimetableSingleReport = async (
  uuid: string,
  type: ReportType,
  subType: ReportSubType | undefined,
) => {
  const response = await apiHelper<Response>({
    method: 'POST',
    path: `/pdf/timetablezip/${uuid}/${subType}`,
  });

  if (response.ok) {
    showSuccessCreateToast();
  }
};

export const createTeachingLoadReport = async (
  uuid: string,
  scenario: string,
  type: ReportType,
  subType: ReportSubType | undefined,
) => {
  const response = await apiHelper<Response>({
    method: 'POST',
    path: `/pdf/${type}/${uuid}/${scenario}/${subType}`,
  });

  if (response.ok) {
    showSuccessCreateToast();
  }
};
