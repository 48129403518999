import { Grid, GridRow } from '@bp/ui-components';
import { t } from 'i18next';
import { TimetablePrintConfigurationBaseData } from './TimetablePrintConfigurationBaseData/TimetablePrintConfigurationBaseData';
import { TimetablePrintConfigurationGrid } from './TimetablePrintConfigurationGrid/TimetablePrintConfigurationGrid';

export enum TimetablePrintColorOrigins {
  subject,
  teacher,
  room,
  class,
}

export enum TimetablePrintColorPositions {
  full,
  top,
  right,
  bottom,
  left,
}

export enum TimetablePrintConfigurationOptions {
  printClasses,
  scaleText,
  exactMinute,
  printSubtitle,
  printLogo,
}

export enum TimetablePrintLayoutOptions {
  empty,
  subjectShort,
  subjectLong,
  groupName,
  className,
  classAndGroup,
  teacherShort,
  teacherLong,
  roomName,
  roomNumber,
  teacherAndRoom,
}

export enum TimetablePrintLayoutPositions {
  topLeft,
  topCenter,
  topRight,
  center,
  bottomLeft,
  bottomCenter,
  bottomRight,
}

export type TimetablePrintBaseData = {
  color: string;
  position: TimetablePrintColorPositions;
  config: Record<TimetablePrintConfigurationOptions, boolean>;
};

export type TimetablePrintLayoutData = Record<
  TimetablePrintLayoutPositions,
  { option: TimetablePrintLayoutOptions | null; preview: string }
>;

type TimetablePrintConfigurationProps = {
  baseData: TimetablePrintBaseData;
  layoutData: TimetablePrintLayoutData;
  onLayoutChange: (data: TimetablePrintLayoutData) => void;
  onBaseDataChange: (data: TimetablePrintBaseData) => void;
};

export const TimetablePrintConfiguration = ({
  onLayoutChange,
  onBaseDataChange,
  baseData,
  layoutData,
}: TimetablePrintConfigurationProps) => {
  const subjectColor = '#C0CEF2';
  const teacherColor = '#F2C1C0';
  const roomColor = '#C8F2C0';
  const classColor = '#FAECB9';

  function handleColorOriginChange(origin: TimetablePrintColorOrigins) {
    let color = subjectColor;
    switch (origin) {
      case TimetablePrintColorOrigins.teacher:
        color = teacherColor;
        break;
      case TimetablePrintColorOrigins.room:
        color = roomColor;
        break;
      case TimetablePrintColorOrigins.class:
        color = classColor;
        break;
    }
    onBaseDataChange({ ...baseData, color });
  }

  function handleColorPositionChange(position: TimetablePrintColorPositions) {
    onBaseDataChange({ ...baseData, position });
  }

  function handleConfigChange(config: TimetablePrintConfigurationOptions, value: boolean) {
    onBaseDataChange({
      ...baseData,
      config: {
        ...baseData.config,
        [TimetablePrintConfigurationOptions[config]]: value,
      },
    });
  }

  function handleLayoutChange(position: TimetablePrintLayoutPositions, option: TimetablePrintLayoutOptions | null) {
    let preview = '';
    switch (option) {
      case TimetablePrintLayoutOptions.subjectShort:
        preview = t('timetable.configuration.preview.subjectShort');
        break;
      case TimetablePrintLayoutOptions.subjectLong:
        preview = t('timetable.configuration.preview.subjectLong');
        break;
      case TimetablePrintLayoutOptions.groupName:
        preview = t('timetable.configuration.preview.groupName');
        break;
      case TimetablePrintLayoutOptions.className:
        preview = t('timetable.configuration.preview.className');
        break;
      case TimetablePrintLayoutOptions.classAndGroup:
        preview = t('timetable.configuration.preview.classAndGroup');
        break;
      case TimetablePrintLayoutOptions.teacherShort:
        preview = t('timetable.configuration.preview.teacherShort');
        break;
      case TimetablePrintLayoutOptions.teacherLong:
        preview = t('timetable.configuration.preview.teacherLong');
        break;
      case TimetablePrintLayoutOptions.roomName:
        preview = t('timetable.configuration.preview.roomName');
        break;
      case TimetablePrintLayoutOptions.roomNumber:
        preview = t('timetable.configuration.preview.roomNumber');
        break;
      case TimetablePrintLayoutOptions.teacherAndRoom:
        preview = t('timetable.configuration.preview.teacherAndRoom');
        break;
    }
    onLayoutChange({
      ...layoutData,
      [position]: { option, preview },
    });
  }

  return (
    <Grid>
      <GridRow spacingTop='none' spacingBottom='l'>
        <TimetablePrintConfigurationBaseData
          onOriginChange={handleColorOriginChange}
          onPositionChange={handleColorPositionChange}
          onConfigChange={handleConfigChange}
        />
      </GridRow>
      <GridRow headline={t('timetable.configuration.positions')}>
        <TimetablePrintConfigurationGrid
          data={layoutData}
          color={baseData.color}
          colorPosition={baseData.position}
          onLayoutChange={handleLayoutChange}
        />
      </GridRow>
    </Grid>
  );
};
