import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TeachingLoadReports } from '../../../../components/EvaluateTeachingLoad/TeachingLoadReports';

export const CurrentReports = () => {
  const { t } = useTranslation();

  const tableData = useMemo(() => {
    return [
      { id: 'current-single-teacher', name: t('pdf.currentDetails') },
      { id: '', name: t('common.notYet', { planned: t('pdf.currentOverview') }) },
      { id: '', name: t('common.notYet', { planned: t('pdf.currentSubjectGroups') }) },
      { id: '', name: t('common.notYet', { planned: t('pdf.currentKeyFigures') }) },
      { id: '', name: t('common.notYet', { planned: t('pdf.excelExport') }) },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TeachingLoadReports tableData={tableData} />;
};
