import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TimetableReports } from '../../../components/EvaluateTimetable/TimetableReports';
export const EvaluateByRooms = () => {
  const { t } = useTranslation();

  const tableData = useMemo(() => {
    return [
      { id: 'single-room', name: t('pdf.singlePlans') },
      { id: 'door-signs', name: t('pdf.doorSigns') },
      { id: 'all-rooms', name: t('pdf.overallPlans') },
      { id: '', name: t('common.notYet', { planned: t('pdf.epochPlans') }) },
      { id: '', name: t('common.notYet', { planned: t('pdf.annualPlans') }) },
      { id: '', name: t('common.notYet', { planned: t('pdf.annualOverviewPlans') }) },
      { id: '', name: t('common.notYet', { planned: t('pdf.annualCompactPlans') }) },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TimetableReports tableData={tableData} topic={'rooms'} />;
};
