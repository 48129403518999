import { FC, memo, ReactNode } from 'react';
import styles from './IconMenu.module.scss';
import classNames from 'classnames';

type IconMenuProps = {
  fullWidth?: boolean;
  headline?: string;
  hasMarginBottom?: boolean;
  hasMarginTop?: boolean;
  children?: ReactNode | undefined;
  className?: string;
};

const IconMenu: FC<IconMenuProps> = ({
  fullWidth = false,
  headline,
  hasMarginTop,
  hasMarginBottom,
  children,
  className,
}) => {
  const classes = classNames(
    styles['icon-menu'],
    {
      [styles['full-width']]: fullWidth,
      [styles['margin-top']]: hasMarginTop,
      [styles['margin-bottom']]: hasMarginBottom,
    },
    className,
  );

  return (
    <div className={classes}>
      {headline && <div className={styles.headline}>{headline}</div>}
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default memo(IconMenu);
