import { Checkbox, Grid, GridColumn, GridRow, Select, SelectOptionType } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import styles from './TimetablePrintConfigurationBaseData.module.scss';
import {
  TimetablePrintColorOrigins,
  TimetablePrintColorPositions,
  TimetablePrintConfigurationOptions,
} from '../TimetablePrintConfiguration';

type TimetablePrintConfigurationBaseDataProps = {
  onOriginChange: (origin: TimetablePrintColorOrigins) => void;
  onPositionChange: (position: TimetablePrintColorPositions) => void;
  onConfigChange: (config: TimetablePrintConfigurationOptions, value: boolean) => void;
};

export const TimetablePrintConfigurationBaseData = ({
  onOriginChange,
  onPositionChange,
  onConfigChange,
}: TimetablePrintConfigurationBaseDataProps) => {
  const { t } = useTranslation();

  const originOptions: SelectOptionType[] = [
    {
      label: t('rooms.title.singular'),
      value: TimetablePrintColorOrigins.room,
    },
    {
      label: t('subject.title.singular'),
      value: TimetablePrintColorOrigins.subject,
    },
    {
      label: t('persons.title.singular'),
      value: TimetablePrintColorOrigins.teacher,
    },
    {
      label: t('classes.title.singular'),
      value: TimetablePrintColorOrigins.class,
    },
  ];

  const positionOptions: SelectOptionType[] = [
    {
      label: t('timetable.configuration.options.full'),
      value: TimetablePrintColorPositions.full,
    },
    {
      label: t('timetable.configuration.options.top'),
      value: TimetablePrintColorPositions.top,
    },
    {
      label: t('timetable.configuration.options.right'),
      value: TimetablePrintColorPositions.right,
    },
    {
      label: t('timetable.configuration.options.bottom'),
      value: TimetablePrintColorPositions.bottom,
    },
    {
      label: t('timetable.configuration.options.left'),
      value: TimetablePrintColorPositions.left,
    },
  ];

  return (
    <Grid className={styles['timetable-print-configuration-base-data']} useFormGap>
      <GridRow spacingBottom='none' spacingTop='none'>
        <GridColumn width={6}>
          <Select
            options={originOptions}
            defaultValue={originOptions.find((o) => o.value === TimetablePrintColorOrigins.subject)}
            onChange={(option) => onOriginChange((option as SelectOptionType).value as TimetablePrintColorOrigins)}
            name={'color-origin'}
            label={t('timetable.configuration.colorOrigin')}
          />
        </GridColumn>
        <GridColumn width={6}>
          <Select
            options={positionOptions}
            defaultValue={positionOptions.find((o) => o.value === TimetablePrintColorPositions.full)}
            onChange={(option) => onPositionChange((option as SelectOptionType).value as TimetablePrintColorPositions)}
            name={'color-position'}
            label={t('timetable.configuration.colorPosition')}
          />
        </GridColumn>
      </GridRow>
      <GridRow spacingTop='none' spacingBottom='none'>
        <GridColumn width={3}>
          <Checkbox
            name={'print-classes'}
            onChange={(event) => onConfigChange(TimetablePrintConfigurationOptions.printClasses, event.target.checked)}
            label={t('timetable.configuration.printClasses')}
          />
          <Checkbox
            name={'scale-text'}
            onChange={(event) => onConfigChange(TimetablePrintConfigurationOptions.scaleText, event.target.checked)}
            label={t('timetable.configuration.scaleText')}
          />
          <Checkbox
            name={'exact-minute'}
            onChange={(event) => onConfigChange(TimetablePrintConfigurationOptions.exactMinute, event.target.checked)}
            label={t('timetable.configuration.exactMinute')}
          />
        </GridColumn>
        <GridColumn width={3}>
          <Checkbox
            name={'print-subtitle'}
            onChange={(event) => onConfigChange(TimetablePrintConfigurationOptions.printSubtitle, event.target.checked)}
            label={t('timetable.configuration.printSubtitle')}
          />
          <Checkbox
            name={'print-logo'}
            onChange={(event) => onConfigChange(TimetablePrintConfigurationOptions.printLogo, event.target.checked)}
            label={t('timetable.configuration.printLogo')}
          />
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
