import dayjs from 'dayjs';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { FC, useMemo, useState } from 'react';
import {
  useDeleteTimetableVersionsMutation,
  useDuplicateTimetableVersionMutation,
  useTimetableDraftQuery,
  useTimetableQuery,
  useUpdateTimetableVersionMutation,
} from '../../../types/planung-graphql-client-defs';
import styles from './TimetableVersionsOverview.module.scss';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EditIcon,
  EmptyState,
  LockIcon,
  Tooltip,
} from '@bp/ui-components';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from '../../../hooks/useConfirm';
import { TimetableVersionModalForm } from '../Form/TimetableVersionModalForm';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import {
  showSuccessDeleteToast,
  showSuccessDuplicateToast,
  showSuccessSaveToast,
  showSuccessUpdateToast,
  showUserErrorToast,
} from '../../../utils/toast';
import { exportTimetableVersionToAsc } from '../../../utils/timetable/timetableVersion';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { TimetableType } from '../../Timetable/graphql';

export type TimetableVersion = {
  uuid: string;
  active: boolean;
  version: number;
  updatedAt: string;
  description: string;
  status: number;
};

type TimetableVersionsOverviewProps = {
  timetable: TimetableType | null;
  onNavigate: () => void;
};

export const TimetableVersionsOverview: FC<TimetableVersionsOverviewProps> = ({ timetable, onNavigate }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const context = useMemorizedCacheTag('TIMETABLE_VERSIONS');
  const { confirm, ConfirmationDialog } = useConfirm();
  const { pimAuthClaims } = useAuthClaims();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const [loading, setLoading] = useState<string | null>(null);
  const [versionToEdit, setVersionToEdit] = useState<TimetableVersion | null>(null);

  const [, updateVersion] = useUpdateTimetableVersionMutation();
  const [, deleteVersion] = useDeleteTimetableVersionsMutation();
  const [, duplicateVersion] = useDuplicateTimetableVersionMutation();

  const [{ data }] = useTimetableDraftQuery({
    variables: {
      draftUuid: timetable?.uuid ?? '',
    },
    context,
  });

  const memoizedData: TimetableVersion[] = useMemo(() => {
    const versions = data?.timetables[0].versions
      ? data?.timetables.filter((tt) => tt.uuid === timetable?.uuid)[0].versions
      : [];

    versions.sort((a, b) => {
      const aDate = a.updatedAt ? dayjs(a.updatedAt) : dayjs(a.createdAt);
      const bDate = b.updatedAt ? dayjs(b.updatedAt) : dayjs(b.createdAt);
      return aDate.isSameOrBefore(bDate) ? 1 : -1;
    });

    return versions.map((version) => {
      return {
        uuid: version.uuid,
        active: version.active ?? false,
        version: version.version,
        updatedAt: version.updatedAt
          ? dayjs(version.updatedAt).format('DD.MM.YYYY - HH:mm')
          : dayjs(version.createdAt).format('DD.MM.YYYY - HH:mm'),
        description: version.description ?? '',
        status:
          !version.placedCardsCount || !version.cardsAggregate || version.cardsAggregate.count === 0
            ? 0
            : Math.floor((version.placedCardsCount / version.cardsAggregate.count) * 100),
      };
    });
  }, [data, timetable]);

  const [{ data: timetableQueryResult }] = useTimetableQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: schoolYear?.uuid,
        },
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context,
  });

  const activeVersion = memoizedData.find((v) => v.active);
  const otherVersions = memoizedData.filter((t) => t.uuid !== activeVersion?.uuid);

  const versionSubMenu = (activeVersion: TimetableVersion) => {
    const originalTimeGrids = timetableQueryResult?.timetables
      .find((tt) => tt.uuid === timetable?.uuid)
      ?.timegridConfigs.map((tgc) => tgc.timegrid?.uuid);

    const allTimeGrids = timetableQueryResult?.timetables.flatMap((tt) =>
      tt.timegridConfigs.map((tgc) => tgc.timegrid?.uuid),
    );
    const otherTimeGrids = allTimeGrids ? allTimeGrids.filter((uuid) => originalTimeGrids?.includes(uuid)) : [];

    const validTargets = timetableQueryResult
      ? timetableQueryResult.timetables.filter((targetTimeTable) => {
          const timeTableTimeGrids = targetTimeTable.timegridConfigs.map(
            (timeGridConfig) => timeGridConfig.timegrid?.uuid,
          );
          const targetTimeTableClasses = targetTimeTable.timegridConfigs.flatMap((timeGridConfig) =>
            timeGridConfig.classes.map((c) => c.uuid),
          );

          const sourceTimeTableClasses = timetable?.timegridConfigs.flatMap((timeGridConfig) =>
            timeGridConfig.classes.map((c) => c.uuid),
          );

          return (
            // has same timeGrid
            otherTimeGrids?.some((item) => timeTableTimeGrids.includes(item)) &&
            // has same  classes
            sourceTimeTableClasses?.every((item) => targetTimeTableClasses.includes(item)) &&
            // not the original timetable
            targetTimeTable.uuid !== timetable?.uuid
          );
        })
      : [];

    return validTargets.map((t) => ({
      label: t.name,
      onClick: () => onMove(t.uuid, [activeVersion.uuid]),
    }));
  };

  function onDataNavigate(uuid: string) {
    onNavigate();
    navigate(
      `${t('routes.versions.slug')}/${uuid}/${t('routes.versionPages.data.slug')}/${t('routes.versionsFilter.class.slug')}`,
    );
  }

  function onBoardNavigate(uuid: string) {
    onNavigate();
    navigate(`${t('routes.versions.slug')}/${uuid}/${t('routes.versionPages.board.slug')}`);
  }

  async function onSave(editVersion: Partial<TimetableVersion>) {
    if (!editVersion || !editVersion.uuid) return;
    setLoading(editVersion.uuid ?? null);

    if (editVersion.active === true) {
      const currentActiveVersion = memoizedData.find((v) => v.active);
      if (currentActiveVersion) {
        await updateVersion({ versionUuids: [currentActiveVersion.uuid], update: { active: false } }, context);
      }
    }

    const result = await updateVersion(
      {
        versionUuids: [editVersion.uuid],
        update: { active: editVersion.active, description: editVersion.description },
      },
      context,
    );
    if (!result || result.error) {
      showUserErrorToast({ text: t('common.errorToastText'), error: result.error });
    } else {
      showSuccessSaveToast();
    }
    setLoading(null);
    setVersionToEdit(null);
  }

  const onDuplicate = async (version: TimetableVersion) => {
    setLoading(version.uuid);
    const result = await duplicateVersion(
      {
        timetableVersionUuid: version.uuid,
        options: {},
        organizationUuid: pimAuthClaims.getOrganizationUuid(),
      },
      context,
    );
    if (!result || result.error) {
      showUserErrorToast({ error: result.error });
    } else {
      showSuccessDuplicateToast();
    }
    setLoading(null);
  };

  const onExport = async (version: TimetableVersion) => {
    const controlResult = await exportTimetableVersionToAsc(version.uuid);
    if (controlResult) {
      const file = new Blob([controlResult], { type: 'text/xml' });
      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = `export-${version.uuid}.xml`;
      document.body.appendChild(a);
      a.click();
    }
  };

  const onMove = async (targetUuid: string, versionsUuids: string[]) => {
    const targetName = timetableQueryResult?.timetables.filter((timetable) => timetable.uuid === targetUuid)[0].name;
    let versionName = '';
    timetableQueryResult?.timetables.forEach((timetable) => {
      const v = timetable.versions.find((v) => versionsUuids.includes(v.uuid));
      if (v?.description) versionName = v.description;
    });

    const confirmed = await confirm({
      title: t('common.move'),
      message: t('teachingBlockVersion.moveVersion', { versionName, targetName }),
    });

    if (!confirmed) return;
    const result = await updateVersion(
      {
        versionUuids: versionsUuids,
        update: {
          timetable: {
            disconnect: {},
            connect: { where: { node: { uuid: targetUuid } } },
          },
        },
      },
      context,
    );
    if (!result || result.error) {
      showUserErrorToast({ error: result.error });
    } else {
      showSuccessUpdateToast();
    }
  };

  async function onDelete(version: TimetableVersion) {
    const confirmed = await confirm({ title: t('common.delete') });
    if (!confirmed) return;
    setLoading(version.uuid);
    const result = await deleteVersion({ versionUuids: [version.uuid] }, context);
    if (!result || result.error) {
      showUserErrorToast({ error: result.error });
    } else {
      showSuccessDeleteToast();
    }
    setLoading(null);
  }

  function handleClose() {
    setLoading(null);
    setVersionToEdit(null);
  }

  return (
    <div className={styles['versions-overview']}>
      <div className={styles.active}>
        <div className={styles.hint}>{t('timetableVersion.activeVersion')}</div>
        <div className={styles.list}>
          <div className={styles.header}>
            <div className={styles.version}>{t('timetableVersion.title.singular')}</div>
            <div className={styles.edited}>{t('common.updatedAt')}</div>
            <div className={styles.name}>{t('common.name')}</div>
            <div className={styles.status}>{t('common.status')}</div>
            <div className={styles.actions}></div>
          </div>
          {activeVersion ? (
            <div className={styles.data}>
              <div className={styles.version}>{activeVersion.version}</div>
              <div className={styles.edited}>{activeVersion.updatedAt}</div>
              <div className={styles.name}>{activeVersion.description}</div>
              <div className={styles.status}>
                {`${activeVersion.status}%`}
                {timetable?.active && (
                  <Tooltip triggerClass={styles.lock} content={t('timetable.locked')}>
                    <LockIcon />
                  </Tooltip>
                )}
              </div>
              <div className={styles.actions}>
                <Button hierarchy='tertiary' onClick={() => onDataNavigate(activeVersion.uuid)}>
                  {t('timetable.timetableData')}
                </Button>
                <Button hierarchy='tertiary' className='mr-3' onClick={() => onBoardNavigate(activeVersion.uuid)}>
                  {t('pinboard.title')}
                </Button>
                <ButtonGroup loading={!!loading && loading === activeVersion.uuid}>
                  <Button
                    icon={<EditIcon className='small' />}
                    hierarchy='tertiary'
                    onClick={() => setVersionToEdit(activeVersion)}
                  />
                  <Dropdown
                    usePortal={false}
                    trigger={<Button icon={<DotsHorizontalIcon className='small' />} hierarchy='tertiary' />}
                  >
                    <DropdownMenu
                      data={[
                        {
                          label: t('common.duplicating'),
                          onClick: () => onDuplicate(activeVersion),
                        },

                        {
                          label: t('common.export'),
                          onClick: () => onExport(activeVersion),
                        },
                        {
                          label: t('common.delete'),
                          type: 'error',
                          disabled: timetable?.active,
                          onClick: () => onDelete(activeVersion),
                        },
                      ]}
                    />
                  </Dropdown>
                </ButtonGroup>
              </div>
            </div>
          ) : (
            <EmptyState
              title={t('timetableVersion.noActiveVersion')}
              subtitle={t('timetableVersion.noActiveVersionHint')}
              size='small'
              forcedHeight='100px'
              hideIcon
            />
          )}
        </div>
      </div>
      <div className={styles.versions}>
        <div className={styles.hint}>{t('timetableVersion.pastVersions')}</div>
        <div className={styles.list}>
          <div className={styles.header}>
            <div className={styles.version}>{t('timetableVersion.title.singular')}</div>
            <div className={styles.edited}>{t('common.updatedAt')}</div>
            <div className={styles.name}>{t('common.name')}</div>
            <div className={styles.status}>{t('common.status')}</div>
            <div className={styles.actions}></div>
          </div>
          {otherVersions.length === 0 ? (
            <EmptyState
              title={t('timetableVersion.noPastVersions')}
              subtitle={t('timetableVersion.noPastVersionsHint')}
              size='small'
              forcedHeight='100px'
              hideIcon
            />
          ) : (
            <div className={classNames(styles['data-wrapper'])}>
              {otherVersions.map((version) => {
                const menu: DropdownMenuItem[] = [
                  {
                    label: t('common.duplicating'),
                    onClick: () => onDuplicate(version),
                  },
                  {
                    label: t('common.export'),
                    onClick: () => onExport(version),
                  },
                  {
                    label: t('common.delete'),
                    type: 'error',
                    onClick: () => onDelete(version),
                  },
                ];

                const subMenu = versionSubMenu(version);
                if (subMenu.length > 0) {
                  menu.splice(1, 0, {
                    label: t('common.move'),
                    subContent: subMenu,
                  });
                }

                return (
                  <div className={styles.data} key={version.uuid}>
                    <div className={styles.version}>{version.version}</div>
                    <div className={styles.edited}>{version.updatedAt}</div>
                    <div className={styles.name}>{version.description}</div>
                    <div className={styles.status}>{`${version.status}%`}</div>
                    <div className={styles.actions}>
                      <Button hierarchy='tertiary' onClick={() => onDataNavigate(version.uuid)}>
                        {t('timetable.timetableData')}
                      </Button>
                      <Button hierarchy='tertiary' className='mr-3' onClick={() => onBoardNavigate(version.uuid)}>
                        {t('pinboard.title')}
                      </Button>
                      <ButtonGroup loading={!!loading && loading === version.uuid}>
                        <Button
                          icon={<EditIcon className='small' />}
                          hierarchy='tertiary'
                          onClick={() => setVersionToEdit(version)}
                        />
                        <Dropdown
                          usePortal={false}
                          trigger={<Button icon={<DotsHorizontalIcon className='small' />} hierarchy='tertiary' />}
                        >
                          <DropdownMenu usePortal={false} data={menu} />
                        </Dropdown>
                      </ButtonGroup>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <TimetableVersionModalForm
        editVersion={versionToEdit}
        loading={!!loading}
        onSave={onSave}
        onClose={handleClose}
        timeTableIsActive={!!timetable?.active}
      />

      <ConfirmationDialog />
    </div>
  );
};
