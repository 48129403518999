import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FactorType,
  SortDirection,
  useDeleteTeachingLoadFactorMutation,
  useTeachingLoadFactorsQuery,
  useUpdateTeachingLoadFactorMutation,
} from '../../types/planung-graphql-client-defs';
import { TeachingLoadFactor } from './graphql/types';
import {
  AddIcon,
  Button,
  ButtonGroup,
  DeleteIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EditIcon,
  Modal,
  Row,
  Table,
  TableColumns,
  useDefaultSelecting,
} from '@bp/ui-components';
import { EpochFactorForm } from './Forms/EpochFactorForm';
import { useHiddenColumns } from '../../hooks/useHiddenColumns';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { observer } from 'mobx-react-lite';
import { useColumnsSort } from '../../hooks/useColumnsSort';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { TeacherFactorForm } from './Forms/TeacherFactorForm';
import { SubjectFactorForm } from './Forms/SubjectFactorForm';
import { useConfirm } from '../../hooks/useConfirm';
import { showSuccessDeleteToast, showUserErrorToast } from '../../utils/toast';

export const FactorsTable = observer(() => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('common.delete'),
  });

  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('subjects-list');
  const { sorting, saveSorting } = useColumnsSort('subjects-list');
  const { rowSelection, onRowSelectionChange } = useDefaultSelecting();

  const [teacherModalOpen, setTeacherModalOpen] = useState<boolean>(false);
  const [epochModalOpen, setEpochModalOpen] = useState<boolean>(false);
  const [subjectModalOpen, setSubjectModalOpen] = useState<boolean>(false);

  const [teachingLoadFactor, setTeachingLoadFactor] = useState<null | TeachingLoadFactor>(null);

  const context = useMemorizedCacheTag('TEACHINGLOADFACTOR');
  const [{ data }] = useTeachingLoadFactorsQuery({
    context,
    variables: {
      where: { schoolYear: { uuid: currentSchoolYear?.uuid } },
      options: {
        sort: [{ active: SortDirection.Desc, factorType: SortDirection.Desc }],
      },
    },
  });
  const [, deleteTeachingLoadFactor] = useDeleteTeachingLoadFactorMutation();
  const [, updateTeachingLoadFactor] = useUpdateTeachingLoadFactorMutation();

  const tableColumns = useMemo((): TableColumns<TeachingLoadFactor>[] => {
    return [
      {
        header: t('common.type'),
        id: 'factorName',
        accessorKey: 'factorName',
        size: 80,
      },
      {
        header: t('classes.level.full'),
        id: 'grades',
        accessorKey: 'grades',
        size: 110,
      },
      {
        header: t('gradeGroups.title.singular'),
        id: 'gradeGroups',
        accessorKey: 'gradeGroups',
        size: 110,
        cell: (data) => data.row.original.gradeGroup.map((s) => s.name).join(', '),
      },
      {
        header: t('subject.name'),
        id: 'subject',
        accessorKey: 'subject',
        size: 110,
        cell: (data) => data.row.original.subject.map((s) => s.name).join(', '),
      },
      {
        header: t('subjectContainer.titleSingular'),
        id: 'subjectContainer',
        accessorKey: 'subjectContainer',
        size: 80,
        cell: (data) => data.row.original.subjectContainer.map((s) => s.shortName).join(', '),
      },
      {
        header: t('common.timeGrids'),
        id: 'timeGrids',
        size: 80,
        cell: (data) => data.row.original.timeGridEntries.map((t) => t.name).join(', '),
      },
      {
        header: t('teachers.titleSingular'),
        id: 'teacher',
        accessorKey: 'teacher',
        size: 150,
        canExpand: true,
        cell: (data) => data.row.original.teachers.map((s) => s.shortName).join(', '),
      },

      {
        header: t('factors.titleSingular'),
        accessorKey: 'valueRaw',
        id: 'value',
        alignment: 'right',
        size: 90,
      },

      {
        header: t('factors.titleSingularPartial'),
        accessorKey: 'valuePartialRaw',
        id: 'valuePartial',
        alignment: 'right',
        size: 90,
      },

      {
        header: t('common.comment'),
        accessorKey: 'comment',
        id: 'comment',
        size: 150,
      },
      {
        header: t('common.active.full'),
        accessorKey: 'active',
        meta: {
          filterName: t('common.active.full'),
          tooltip: t('common.active.full'),
        },
        id: 'active',
        type: 'active',
      },
    ];
  }, [t]);

  const handleDelete = useCallback(
    async (uuids: string[]) => {
      const res = await confirmDelete();
      if (res) {
        const response = await deleteTeachingLoadFactor({ where: { uuid_IN: uuids } }, context);
        if (response.error) {
          showUserErrorToast({ error: response.error });
        } else {
          showSuccessDeleteToast();
        }
      }
    },
    [deleteTeachingLoadFactor],
  );

  const createBulkEditItems = useCallback(
    (rows: Row<TeachingLoadFactor>[]): DropdownMenuItem[] => {
      const bulkActivate = async (rows: Row<TeachingLoadFactor>[]) => {
        const promises = rows.map(async (row) => {
          await updateTeachingLoadFactor(
            {
              update: { active: !row.original.active },
              where: {
                uuid: row.original.uuid,
              },
            },
            context,
          );
        });

        return await Promise.all(promises);
      };

      return [
        {
          label: t('common.activate'),
          onClick: async () => {
            await bulkActivate(rows);
          },
        },
        {
          type: 'ruler',
        },
        {
          label: t('common.delete') as string,
          type: 'error',
          onClick: async () => {
            await handleDelete(rows.map(({ original }) => original.uuid));
          },
        },
      ];
    },
    [handleDelete],
  );

  const memoizedData = useMemo((): TeachingLoadFactor[] => {
    return (
      data?.teachingLoadFactors.map((factor) => {
        let factorName;
        switch (factor.factorType) {
          case FactorType.Time:
            factorName = t('factors.time');
            break;
          case FactorType.Base:
            factorName = t('factors.base');
            break;
          case FactorType.Person:
            factorName = t('factors.person');
            break;
        }
        return { ...factor, factorName };
      }) ?? []
    );
  }, [data]);

  const handleEdit = (subjectRow: Row<TeachingLoadFactor>) => {
    setTeachingLoadFactor(subjectRow.original);
    switch (subjectRow.original.factorType) {
      case FactorType.Time:
        setEpochModalOpen(true);
        break;
      case FactorType.Base:
        setSubjectModalOpen(true);
        break;
      case FactorType.Person:
        setTeacherModalOpen(true);
        break;
    }
  };

  const addFactorTypeItems: DropdownMenuItem[] = [
    {
      label: t('factors.base'),
      type: 'default',
      onClick: () => {
        setSubjectModalOpen(true);
      },
    },
    {
      label: t('factors.time'),
      type: 'default',
      onClick: () => {
        setEpochModalOpen(true);
      },
    },
    {
      label: t('factors.person'),
      type: 'default',
      onClick: () => {
        setTeacherModalOpen(true);
      },
    },
  ];

  return (
    <>
      <Table<TeachingLoadFactor>
        showBorderRadius
        showShadow
        canScroll
        breakpoint={null}
        rowSelection={rowSelection}
        onRowSelectionChange={onRowSelectionChange}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={saveColumnVisibility}
        sorting={sorting}
        onSortingChange={saveSorting}
        columns={tableColumns}
        data={memoizedData}
        showSelect
        showVisibility
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('lessonUnit.factors')} - ${t('common.schoolYear')} ${currentSchoolYear?.shortName}`,
          filename: `${t('lessonUnit.factors')}_${currentSchoolYear?.shortName}`,
        }}
        showActionBar
        actionBarSettings={{
          showExpertFilter: true,
          showPrintButton: true,
          showBulkEdit: true,
          extendedActionsRight: (
            <Dropdown
              noPadding
              trigger={
                <Button hierarchy='tertiary' icon={<AddIcon className={'svg-icon'} />}>
                  {t('common.add')}
                </Button>
              }
            >
              <DropdownMenu data={addFactorTypeItems} />
            </Dropdown>
          ),
        }}
        isOnWhite={false}
        bulkEditDropdownContent={(rows) => {
          return <DropdownMenu data={createBulkEditItems(rows)} />;
        }}
        lastColWidth='80px'
        lastCol={(row) => {
          return (
            <ButtonGroup>
              <Button
                hierarchy={'tertiary'}
                onClick={() => {
                  handleEdit(row);
                }}
                icon={<EditIcon className={'svg-icon small'} />}
              />
              <Button
                hierarchy='secondary'
                type='button'
                onClick={async () => {
                  await handleDelete([row.original.uuid]);
                }}
                icon={<DeleteIcon className='small' />}
              />
            </ButtonGroup>
          );
        }}
      />

      <Modal
        isOpen={epochModalOpen}
        onRequestClose={() => {
          setTeachingLoadFactor(null);
          setEpochModalOpen(false);
        }}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        title={teachingLoadFactor?.uuid ? t('factors.editEpochFactor') : t('factors.addEpochFactor')}
      >
        <EpochFactorForm
          currentSchoolYear={currentSchoolYear ?? null}
          teachingLoadFactor={teachingLoadFactor}
          closeForm={() => {
            setTeachingLoadFactor(null);
            setEpochModalOpen(false);
          }}
        />
      </Modal>

      <Modal
        isOpen={subjectModalOpen}
        onRequestClose={() => {
          setTeachingLoadFactor(null);
          setSubjectModalOpen(false);
        }}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        title={teachingLoadFactor?.uuid ? t('factors.editSubjectFactor') : t('factors.addSubjectFactor')}
      >
        <SubjectFactorForm
          currentSchoolYear={currentSchoolYear ?? null}
          teachingLoadFactor={teachingLoadFactor}
          closeForm={() => {
            setTeachingLoadFactor(null);
            setSubjectModalOpen(false);
          }}
        />
      </Modal>

      <Modal
        isOpen={teacherModalOpen}
        onRequestClose={() => {
          setTeachingLoadFactor(null);
          setTeacherModalOpen(false);
        }}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        title={teachingLoadFactor?.uuid ? t('factors.editTeacherFactor') : t('factors.addTeacherFactor')}
      >
        <TeacherFactorForm
          currentSchoolYear={currentSchoolYear ?? null}
          teachingLoadFactor={teachingLoadFactor}
          closeForm={() => {
            setTeachingLoadFactor(null);
            setTeacherModalOpen(false);
          }}
        />
      </Modal>

      <ConfirmationDialog />
    </>
  );
});
