import styles from './TimetableBlock.module.scss';
import { AddIcon, Button, DotsVerticalIcon, Dropdown, DropdownMenu, LazyLoader, Modal } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import classNames from 'classnames';
import { TimetableSubjectContainerForm } from './Forms/TimetableSubjectContainerForm';
import { getDaysDifference, isAfter, isBefore, niceDate } from '../../utils/dateCalculations';
import { ProcessedTimetableBlockType, useTimetableBlock } from './useTimetableBlock';
import { showSuccessDeleteToast, showUserErrorToast } from '../../utils/toast';
import { SubjectContainer } from './SubjectContainer';
import { Months } from './months/Months';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';

export const TimetableBlock = () => {
  const { t } = useTranslation();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const { deleteTimetableBlock, isLoading, processedBlocks, openModal, hasBlock, TimetableBlockModal } =
    useTimetableBlock();

  const [containerModalOpen, setContainerModalOpen] = useState<boolean>(false);

  const progressRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);

  const schoolYearDays = getDaysDifference(schoolYear?.start, schoolYear?.end);
  const daysPassed = getDaysDifference(new Date(), schoolYear?.end);
  const yearProgressWidthPercent = (daysPassed / schoolYearDays) * 100;

  async function handleDelete(uuid: string) {
    const result = await deleteTimetableBlock(uuid);
    if (result.error) {
      showUserErrorToast({ error: result.error });
    } else {
      showSuccessDeleteToast();
    }
  }

  return (
    <div className={styles['timetable-block']}>
      {isLoading && <LazyLoader className={styles.loader} embedded size='xxl' />}
      <div className={styles['sub-headline']}>{t('timetable.title.plural')}</div>
      <div className={styles.blocks}>
        {processedBlocks.length === 0 ? (
          <div className={classNames(styles.block, styles['empty-state'])}>
            <Button
              className={styles['add-button']}
              hierarchy='tertiary'
              icon={<AddIcon />}
              onClick={() => openModal(null)}
            >
              {t('timetableBlock.addBlock')}
            </Button>
          </div>
        ) : (
          <>
            {processedBlocks
              .filter((b) => {
                return b.days > 1;
              })
              .map((b, index) => {
                const startBeforeToday = isBefore(b.start, new Date());
                const endAfterToday = isAfter(b.end, new Date());
                const isCurrentProgress = startBeforeToday && endAfterToday;
                const showProgress = (startBeforeToday && !endAfterToday) || isCurrentProgress;

                const daysPassed = getDaysDifference(new Date(), b.end);
                const blockWidthPercent = (b.days / schoolYearDays) * 100;
                const blockWidthPixel = itemsRef.current[index]?.clientWidth ?? 0;

                let progressWidthPixel = progressRef.current?.clientWidth ?? 0;
                let remainingWidthPixel = progressWidthPixel;
                for (let i = 0; i < index; i++) {
                  remainingWidthPixel = remainingWidthPixel - (itemsRef.current[i]?.clientWidth ?? 0) + i - 1 * 8;
                }

                if (isCurrentProgress) {
                  progressWidthPixel = remainingWidthPixel;
                }

                if (hasBlock(b)) {
                  const block = b as ProcessedTimetableBlockType;

                  return (
                    <div
                      key={index + block.uuid}
                      className={classNames(styles.block, styles['has-block'])}
                      style={{ flex: `0 1 ${blockWidthPercent}%` }}
                      ref={(el) => (itemsRef.current[index] = el)}
                    >
                      <div className={styles.name}>{block.name ?? t('timetableBlock.title.singular')}</div>
                      <div
                        className={styles['time-span']}
                      >{`${niceDate(new Date(block.start), 'short')} - ${niceDate(new Date(block.end), 'short')}`}</div>
                      <Dropdown
                        triggerClass={styles.actions}
                        trigger={<Button icon={<DotsVerticalIcon className='small' />} hierarchy='ghost' />}
                      >
                        <DropdownMenu
                          data={[
                            {
                              label: t('common.edit'),
                              onClick: () => {
                                openModal(b);
                              },
                            },
                            {
                              label: t('timetableBlock.divide'),
                              disabled: true,
                            },
                            {
                              label: t('timetableBlock.mergeWith'),
                              disabled: true,
                            },
                            {
                              label: t('common.delete'),
                              type: 'error',
                              onClick: () => handleDelete(block.uuid),
                              disabled: daysPassed < block.days,
                            },
                          ]}
                        />
                      </Dropdown>
                      {showProgress && (
                        <div
                          className={styles.progress}
                          style={{
                            width: `${progressWidthPixel >= blockWidthPixel ? blockWidthPixel : progressWidthPixel}px`,
                          }}
                        ></div>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      className={styles.block}
                      style={{ flex: `0 1 ${blockWidthPercent}%` }}
                      ref={(el) => (itemsRef.current[index] = el)}
                    >
                      <Button
                        className={styles.actions}
                        icon={<AddIcon className='small' />}
                        hierarchy='ghost'
                        onClick={() => {
                          openModal(b);
                        }}
                      />
                      {showProgress && (
                        <div
                          className={styles.progress}
                          style={{
                            width: `${progressWidthPixel >= blockWidthPixel ? blockWidthPixel : progressWidthPixel}px`,
                          }}
                        ></div>
                      )}
                    </div>
                  );
                }
              })}
          </>
        )}
      </div>

      <SubjectContainer progress={yearProgressWidthPercent} progressRef={progressRef} />
      <Months />

      <TimetableBlockModal />

      <Modal
        title={t('subjectContainer.add')}
        isOpen={containerModalOpen}
        onRequestClose={() => setContainerModalOpen(false)}
      >
        <TimetableSubjectContainerForm onClose={() => setContainerModalOpen(false)} />
      </Modal>
    </div>
  );
};
