import { useColumnsSort } from '../../../hooks/useColumnsSort';
import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  Row,
  Table,
  useDefaultSelecting,
} from '@bp/ui-components';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TeachingBlockVersionTableType } from '../graphql/types';
import { createTeachingBlockVersionTableColumns } from '../utils/TeachingBlockVersionTableUtils';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import { observer } from 'mobx-react-lite';
import { hexToColorOption } from '../../../utils/colorUtils';
import { useTeachingBlockStore } from '../../TeachingBlockGrid/TeachingBlockContext';

type TeachingBlockVersionClassesTableProps = {
  versionUuid: string | undefined;
  onRowSelection: (rowData: TeachingBlockVersionTableType, index: number) => void;
  onShowLessonClick: (uuid: string) => void;
  versionClassesUuids: string[];
  handleRemove: (rows: Row<TeachingBlockVersionTableType>[]) => void;
};

export const TeachingBlockVersionClassesTable = observer(
  ({
    versionUuid,
    onShowLessonClick,
    versionClassesUuids,
    onRowSelection,
    handleRemove,
  }: TeachingBlockVersionClassesTableProps) => {
    const { t } = useTranslation();
    const { sorting, saveSorting } = useColumnsSort('TeachingBlockVersionClassesTable');
    const { rowSelection, onRowSelectionChange } = useDefaultSelecting({ 0: true });

    const columns = useMemo(() => createTeachingBlockVersionTableColumns(t('classes.title.singular')), []);

    const store = useTeachingBlockStore();
    const usedPlacedClasses = store.placedCards.flatMap((c) => c.lessonClasses.map((lc) => lc.class.uuid));
    const usedFreeClasses = store.freeCards.flatMap((c) => c.lessonClasses.map((lc) => lc.class.uuid));
    const usedClasses = [...usedPlacedClasses, ...usedFreeClasses];

    const { classesData } = useLoadBasicData({ pause: !versionUuid });

    const classTableData: TeachingBlockVersionTableType[] = useMemo(() => {
      return (
        classesData?.classes
          .filter((c) => versionClassesUuids.includes(c.uuid))
          .map((c) => {
            const { html, label } = hexToColorOption(c.timetableConfig?.color ?? '');

            return {
              color: {
                color: html,
                label,
              },
              count: 0,
              name: c.name,
              shortName: c.shortName,
              uuid: c.uuid,
            };
          }) ?? []
      );
    }, [classesData?.classes, versionClassesUuids]);

    const createActionItems = useCallback(
      (row: Row<TeachingBlockVersionTableType>): DropdownMenuItem[] => {
        return [
          {
            label: t('teachingBlockVersion.editData'),
          },
          {
            label: t('teachingBlockVersion.test'),
          },
          {
            label: t('teachingBlockVersion.remove'),
            type: 'error',
            disabled: usedClasses.includes(row.original.uuid),
            onClick: () => {
              handleRemove([row]);
            },
          },
        ];
      },
      [handleRemove, usedClasses],
    );

    return (
      <Table<TeachingBlockVersionTableType>
        showBorderRadius
        showShadow
        canScroll
        minHeight={900}
        sorting={sorting}
        onSortingChange={saveSorting}
        columns={columns}
        data={classTableData}
        lastColWidth='140px'
        lastCol={(row) => {
          return (
            <ButtonGroup>
              <Button hierarchy='secondary' onClick={() => onShowLessonClick(row.original.uuid)}>
                {t('teachingBlockVersion.showLessons')}
              </Button>
              <Dropdown
                noPadding
                trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />}
              >
                <DropdownMenu data={createActionItems(row)} />
              </Dropdown>
            </ButtonGroup>
          );
        }}
        showRowHover
        onRowClick={(event, row) => {
          if (onRowSelectionChange) onRowSelectionChange({ [row.id]: true });
          onRowSelection(row.original, row.index);
          document.querySelector('#content')?.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        rowSelection={rowSelection}
        showSelected
      />
    );
  },
);
