import { Card, Grid, GridColumn, GridRow, Table } from '@bp/ui-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { useTeachingBlockQuery, useTimetableBlocksQuery } from '../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { TimetableReportsTable } from './TimetableReportsTable';
import { ReportTopic } from './types';
import { observer } from 'mobx-react-lite';
import { TimetablePrintConfigurationModal } from '../TimetablePrintConfiguration/TimetablePrintConfigurationModal/TimetablePrintConfigurationModal';
import styles from './TimetableReports.module.scss';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';

type TimetableReportsProps = {
  tableData: {
    id: string;
    name: string;
  }[];
  topic: ReportTopic;
};
export const TimetableReports = observer(({ tableData, topic }: TimetableReportsProps) => {
  const { t } = useTranslation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
  const permissionChecker = usePermissionChecker();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showConfigModal, setShowConfigModal] = useState<boolean>(false);

  const timetableBlockContext = useMemorizedCacheTag('TIMETABLE_BLOCK');
  const teachingBlockContext = useMemorizedCacheTag('TEACHINGBLOCK');

  const [{ data: timetableBlocks }] = useTimetableBlocksQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: currentSchoolYear?.uuid,
        },
        timetables_SOME: {
          versions_SINGLE: {
            active: true,
          },
        },
      },
    },
    context: timetableBlockContext,
  });

  const [{ data: teachingBlocks }] = useTeachingBlockQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: currentSchoolYear?.uuid,
        },
        draft: false,
        versions_SOME: {
          active: true,
        },
      },
    },
    context: teachingBlockContext,
  });

  const tableColumns = [
    {
      header: '',
      id: 'name',
      accessorKey: 'name',
      canExpand: true,
    },
  ];

  const [headline, setHeadline] = useState('');

  useEffect(() => {
    setHeadline(tableData[selectedIndex].name);
  }, [selectedIndex, tableData]);

  return (
    <>
      <Grid useFormGap className={styles['timetable-reports']}>
        <GridRow mobileGap={'32px'}>
          <GridColumn width={6}>
            <Card header={{ headline: t('timetable.title.plural') }} contentPadding={'none'}>
              <Table
                hideHeader
                data={tableData}
                columns={tableColumns}
                onRowClick={(e, row) => {
                  setSelectedIndex(parseInt(row.id));
                }}
                lastColWidth={'0'}
                customPadding='var(--spacing-6)'
                showRowHover
                rowSelection={{ [selectedIndex]: true }}
                showSelected
              />
            </Card>
          </GridColumn>
          <GridColumn width={6}>
            <Card
              header={{
                headline,
                actions: [
                  {
                    text: t('timetable.configuration.configure'),
                    disabled: !permissionChecker?.isOmniAdmin(),
                    callback: () => setShowConfigModal(true),
                  },
                ],
              }}
              contentPadding={'none'}
            >
              {topic === 'classes' && (
                <>
                  {tableData[selectedIndex].id !== 'epoch-class' &&
                    tableData[selectedIndex].id !== '' &&
                    timetableBlocks?.timetableBlocks.map((timetableBlock) => (
                      <div key={timetableBlock.uuid} className={styles.block}>
                        <div className={styles.name}>{timetableBlock?.name}</div>
                        <TimetableReportsTable
                          context={timetableBlock}
                          type={'timetable'}
                          topic={topic}
                          subType={tableData[selectedIndex].id}
                        />
                      </div>
                    ))}
                  {tableData[selectedIndex].id === 'epoch-class' &&
                    teachingBlocks?.teachingBlocks.map((teachingBlock) => (
                      <div key={teachingBlock.uuid} className={styles.block}>
                        <div className={styles.name}>{teachingBlock?.name}</div>
                        <TimetableReportsTable
                          topic={topic}
                          context={teachingBlock}
                          type={'teachingblock'}
                          subType={tableData[selectedIndex].id}
                        />
                      </div>
                    ))}
                </>
              )}

              {topic === 'teachers' && (
                <>
                  {tableData[selectedIndex].id !== 'epoch-teacher' &&
                    tableData[selectedIndex].id !== '' &&
                    timetableBlocks?.timetableBlocks.map((timetableBlock) => (
                      <div key={timetableBlock.uuid} className={styles.block}>
                        <div className={styles.name}>{timetableBlock?.name}</div>
                        <TimetableReportsTable
                          context={timetableBlock}
                          type={'timetable'}
                          topic={topic}
                          subType={tableData[selectedIndex].id}
                        />
                      </div>
                    ))}
                  {tableData[selectedIndex].id === 'epoch-teacher' &&
                    teachingBlocks?.teachingBlocks.map((teachingBlock) => (
                      <div key={teachingBlock.uuid} className={styles.block}>
                        <div className={styles.name}>{teachingBlock?.name}</div>
                        <TimetableReportsTable
                          topic={topic}
                          context={teachingBlock}
                          type={'teachingblock'}
                          subType={tableData[selectedIndex].id}
                        />
                      </div>
                    ))}
                </>
              )}

              {topic === 'rooms' && (
                <>
                  {tableData[selectedIndex].id !== '' &&
                    timetableBlocks?.timetableBlocks.map((timetableBlock) => (
                      <div key={timetableBlock.uuid} className={styles.block}>
                        <div className={styles.name}>{timetableBlock?.name}</div>
                        <TimetableReportsTable
                          context={timetableBlock}
                          type={'timetable'}
                          topic={topic}
                          subType={tableData[selectedIndex].id}
                        />
                      </div>
                    ))}
                </>
              )}

              {tableData[selectedIndex].id === '' && (
                <div className={styles.block}>
                  <div className={styles.name}>{t('common.planned')}</div>
                </div>
              )}
            </Card>
          </GridColumn>
        </GridRow>
      </Grid>

      <TimetablePrintConfigurationModal isOpen={showConfigModal} onClose={() => setShowConfigModal(false)} />
    </>
  );
});
