import dayjs from 'dayjs';
import { FC, useMemo, useState } from 'react';
import {
  useDeleteTeachingBlockVersionsMutation,
  useDuplicateTeachingBlockVersionMutation,
  useTeachingBlockDraftQuery,
  useUpdateTeachingBlockVersionsMutation,
  use_DeleteTeachingBlocksMutation,
} from '../../../types/planung-graphql-client-defs';
import styles from './TeachingBlockVersionsOverview.module.scss';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  EditIcon,
  EmptyState,
} from '@bp/ui-components';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from '../../../hooks/useConfirm';
import { TeachingBlockVersionModalForm } from '../Form/TeachingBlockVersionModalForm';
import {
  showSuccessDeleteToast,
  showSuccessDuplicateToast,
  showSuccessSaveToast,
  showUserErrorToast,
} from '../../../utils/toast';
import { useAuthClaims } from '../../../hooks/useAuthClaims';

export type TeachingBlockVersion = {
  uuid: string;
  active: boolean;
  version: number;
  updatedAt: string;
  description: string;
};

type TeachingBlockVersionsOverviewProps = {
  schoolYearUuid: string;
  onNavigate: () => void;
  subjectContainerUuid: string;
};

export const TeachingBlockVersionsOverview: FC<TeachingBlockVersionsOverviewProps> = ({
  schoolYearUuid,
  onNavigate,
  subjectContainerUuid,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();

  const { confirm, ConfirmationDialog } = useConfirm();

  const [, updateVersion] = useUpdateTeachingBlockVersionsMutation();
  const [, deleteVersion] = useDeleteTeachingBlockVersionsMutation();
  const [, duplicateVersion] = useDuplicateTeachingBlockVersionMutation();
  const [, deleteTeachingBlock] = use_DeleteTeachingBlocksMutation();

  const [loading, setLoading] = useState<string | null>(null);
  const [versionToEdit, setVersionToEdit] = useState<TeachingBlockVersion | null>(null);

  const context = useMemo(() => {
    return {
      additionalTypenames: ['TeachingBlockVersion', 'TeachingBlock', 'SubjectContainer', 'TeachingBlockCard'],
    };
  }, []);

  const [{ data }] = useTeachingBlockDraftQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: schoolYearUuid,
        },
        subjectContainer: {
          uuid: subjectContainerUuid,
        },
      },
    },
    context,
  });

  const memoizedData: TeachingBlockVersion[] = useMemo(() => {
    const versions =
      data?.teachingBlocks[0] && data?.teachingBlocks[0].versions ? data?.teachingBlocks[0].versions : [];

    versions.sort((a, b) => {
      const aDate = a.updatedAt ? dayjs(a.updatedAt) : dayjs(a.createdAt);
      const bDate = b.updatedAt ? dayjs(b.updatedAt) : dayjs(b.createdAt);
      return aDate.isSameOrBefore(bDate) ? 1 : -1;
    });

    return versions.map((version) => {
      return {
        uuid: version.uuid,
        active: version.active ?? false,
        version: version.version,
        updatedAt: version.updatedAt
          ? dayjs(version.updatedAt).format('DD.MM.YYYY - HH:mm')
          : dayjs(version.createdAt).format('DD.MM.YYYY - HH:mm'),
        description: version.description ?? '',
      };
    });
  }, [data]);

  const activeVersion = memoizedData.find((v) => v.active);
  const otherVersions = memoizedData.filter((t) => t.uuid !== activeVersion?.uuid);

  function onDataNavigate(uuid: string) {
    onNavigate();
    navigate(
      `${t('routes.teachingBlockVersions.slug')}/${uuid}/${t('routes.versionPages.data.slug')}/${t('routes.versionsFilter.class.slug')}`,
    );
  }

  function onBoardNavigate(uuid: string) {
    onNavigate();
    navigate(`${t('routes.teachingBlockVersions.slug')}/${uuid}/${t('routes.versionPages.board.slug')}`);
  }

  async function onSave(editVersion: Partial<TeachingBlockVersion>) {
    if (!editVersion || !editVersion.uuid) return;
    setLoading(editVersion.uuid);

    if (editVersion.active === true) {
      const currentActiveVersion = memoizedData.find((v) => v.active);
      if (currentActiveVersion) {
        await updateVersion({ where: { uuid: currentActiveVersion.uuid }, update: { active: false } }, context);
      }
    }

    const result = await updateVersion(
      {
        where: { uuid: editVersion.uuid },
        update: {
          active: editVersion.active,
          description: editVersion.description,
        },
      },
      context,
    );
    if (!result || result.error) {
      showUserErrorToast({ text: t('common.errorToastText'), error: result.error });
    } else {
      showSuccessSaveToast();
    }
    setLoading(null);
    setVersionToEdit(null);
  }

  async function onDelete(version: TeachingBlockVersion) {
    const isLastVersion = memoizedData.length === 1;
    const confirmed = await confirm({ title: t('common.delete') });
    if (!confirmed) return;
    setLoading(version.uuid);
    const result = await deleteVersion({ versionUuids: [version.uuid] }, context);
    if (!result || result.error) {
      showUserErrorToast({ error: result.error });
    } else {
      showSuccessDeleteToast();
    }
    setLoading(null);
    if (isLastVersion) {
      deleteTeachingBlock(
        {
          where: {
            schoolYear: { uuid: schoolYearUuid },
            subjectContainer: { uuid: subjectContainerUuid },
            versionsAggregate: { count: 0 },
          },
        },
        context,
      );
      handleClose();
    }
  }

  function handleClose() {
    setLoading(null);
    setVersionToEdit(null);
  }

  const onDuplicate = async (version: TeachingBlockVersion) => {
    setLoading(version.uuid);
    const result = await duplicateVersion(
      {
        teachingBlockVersionUuid: version.uuid,
        options: {},
        organizationUuid: pimAuthClaims.getOrganizationUuid(),
      },
      context,
    );

    if (!result || result.error) {
      showUserErrorToast({ error: result.error });
    } else {
      showSuccessDuplicateToast();
    }
    setLoading(null);
  };

  return (
    <div className={styles['teaching-block-versions-overview']}>
      <div className={styles.active}>
        <div className={styles.hint}>{t('teachingBlockVersion.activeVersion')}</div>
        <div className={styles.list}>
          <div className={styles.header}>
            <div className={styles.version}>{t('teachingBlockVersion.title.singular')}</div>
            <div className={styles.edited}>{t('common.updatedAt')}</div>
            <div className={styles.name}>{t('common.name')}</div>
            <div className={styles.status}>{t('common.status')}</div>
            <div className={styles.actions}></div>
          </div>
          {activeVersion ? (
            <div className={styles.data}>
              <div className={styles.version}>{activeVersion.version}</div>
              <div className={styles.edited}>{activeVersion.updatedAt}</div>
              <div className={styles.name}>{activeVersion.description}</div>
              {/*<div className={styles.status}>{`${activeVersion.status}%`}</div>*/}
              <div className={styles.actions}>
                <Button hierarchy='tertiary' onClick={() => onDataNavigate(activeVersion.uuid)}>
                  {t('teachingBlockVersion.teachingBlockData')}
                </Button>
                <Button hierarchy='tertiary' className='mr-3' onClick={() => onBoardNavigate(activeVersion.uuid)}>
                  {t('pinboard.title')}
                </Button>
                <ButtonGroup loading={!!loading && loading === activeVersion.uuid}>
                  <Button
                    icon={<EditIcon className='small' />}
                    hierarchy='tertiary'
                    onClick={() => setVersionToEdit(activeVersion)}
                  />
                  <Dropdown
                    usePortal={false}
                    trigger={<Button icon={<DotsHorizontalIcon className='small' />} hierarchy='tertiary' />}
                  >
                    <DropdownMenu
                      data={[
                        {
                          label: t('common.deactivate'),
                          onClick: () => onSave({ ...activeVersion, active: false }),
                        },
                        {
                          label: t('common.duplicating'),
                          disabled: true,
                          onClick: () => onDuplicate(activeVersion),
                        },
                        {
                          type: 'ruler',
                        },
                        {
                          label: t('common.delete'),
                          type: 'error',
                          disabled: true,
                          onClick: () => onDelete(activeVersion),
                        },
                      ]}
                    />
                  </Dropdown>
                </ButtonGroup>
              </div>
            </div>
          ) : (
            <EmptyState
              title={t('teachingBlockVersion.noActiveVersion')}
              subtitle={t('teachingBlockVersion.noActiveVersionHint')}
              size='small'
              forcedHeight='100px'
              hideIcon
            />
          )}
        </div>
      </div>
      <div className={styles.versions}>
        <div className={styles.hint}>{t('teachingBlockVersion.pastVersions')}</div>
        <div className={styles.list}>
          <div className={styles.header}>
            <div className={styles.version}>{t('teachingBlockVersion.title.singular')}</div>
            <div className={styles.edited}>{t('common.updatedAt')}</div>
            <div className={styles.name}>{t('common.name')}</div>
            <div className={styles.status}>{t('common.status')}</div>
            <div className={styles.actions}></div>
          </div>
          {otherVersions.length === 0 ? (
            <EmptyState
              title={t('teachingBlockVersion.noPastVersions')}
              subtitle={t('teachingBlockVersion.noPastVersionsHint')}
              size='small'
              forcedHeight='100px'
              hideIcon
            />
          ) : (
            <div className={classNames(styles['data-wrapper'], 'has-scrollbar')}>
              {otherVersions.map((version) => {
                return (
                  <div className={styles.data} key={version.uuid}>
                    <div className={styles.version}>{version.version}</div>
                    <div className={styles.edited}>{version.updatedAt}</div>
                    <div className={styles.name}>{version.description}</div>
                    {/*<div className={styles.status}>{`${version.status}%`}</div>*/}
                    <div className={styles.actions}>
                      <Button hierarchy='tertiary' onClick={() => onDataNavigate(version.uuid)}>
                        {t('teachingBlockVersion.teachingBlockData')}
                      </Button>
                      <Button hierarchy='tertiary' className='mr-3' onClick={() => onBoardNavigate(version.uuid)}>
                        {t('pinboard.title')}
                      </Button>
                      <ButtonGroup>
                        <Button
                          icon={<EditIcon className='small' />}
                          hierarchy='tertiary'
                          onClick={() => setVersionToEdit(version)}
                        />
                        <Dropdown
                          usePortal={false}
                          trigger={<Button icon={<DotsHorizontalIcon className='small' />} hierarchy='tertiary' />}
                        >
                          <DropdownMenu
                            data={[
                              {
                                label: t('common.activate'),
                                onClick: () => onSave({ ...version, active: true }),
                              },
                              {
                                label: t('common.duplicating'),
                                disabled: true,
                                onClick: () => onDuplicate(version),
                              },
                              {
                                type: 'ruler',
                              },
                              {
                                label: t('common.delete'),
                                type: 'error',
                                disabled: true,
                                onClick: () => onDelete(version),
                              },
                            ]}
                          />
                        </Dropdown>
                      </ButtonGroup>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <TeachingBlockVersionModalForm
        editVersion={versionToEdit}
        loading={!!loading}
        onSave={onSave}
        onClose={handleClose}
      />
      <ConfirmationDialog />
    </div>
  );
};
