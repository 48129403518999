import { AddIcon, Card, LazyLoader, Skeleton, Tab } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { TimetableTable } from '../../../components/Timetable/TimetableTable';
import { observer } from 'mobx-react-lite';
import styles from './TimetablePlanPage.module.scss';
import { Page } from '../../../components/Page/Page';
import { TeachingBlocksTable } from '../../../components/Teachingblock/TeachingBlocksTable';
import { TimetableBlock } from '../../../components/TimetableBlock/TimetableBlock';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useTimetableBlock } from '../../../components/TimetableBlock/useTimetableBlock';
import { Suspense } from 'react';

export const TimetablePlanPage = observer(() => {
  const { t } = useTranslation();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const { openModal, TimetableBlockModal, processedBlocks } = useTimetableBlock();

  return (
    <Page
      className={styles['timetable-plan-page']}
      title={`${t('timetable.title.plural')} ${t('timetable.plan').toLowerCase()}`}
      hasOutlet
    >
      <Card
        wrapperClass={styles['timetable-block']}
        header={{
          headline: t('timetableBlock.selectedYear', { year: schoolYear?.name }),
          actions: [
            {
              text: t('timetableBlock.addBlock'),
              icon: <AddIcon className='small' />,
              callback: () => openModal(null),
              disabled: processedBlocks.length > 0,
            },
          ],
        }}
      >
        <TimetableBlock />
        <TimetableBlockModal />
      </Card>
      <Suspense fallback={<LazyLoader />}>
        <Tab
          className={styles['timetable-plan-tabs']}
          tabs={[
            {
              title: t('timetableDraft.title.plural'),
              value: 'drafts',
              content: <TimetableTable />,
            },
            {
              title: t('teachingBlockDraft.title.plural'),
              value: 'teachingBlockDrafts',
              content: <TeachingBlocksTable />,
            },
            {
              title: t('common.archive'),
              value: 'archive',
              content: <Skeleton />,
            },
          ]}
        />
      </Suspense>
    </Page>
  );
});
