import { LazyLoader, OverviewIllustration, PdfIcon, SubjectIllustration } from '@bp/ui-components';
import { Page } from '../../../../components/Page/Page';
import { useTranslation } from 'react-i18next';
import { IconMenuItem } from '../../../../components/IconMenuItem/IconMenuItem';
import IconMenu from '../../../../components/IconMenu/IconMenu';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from '../Planned/EvaluatePlannedPage.module.scss';
import { Suspense } from 'react';
import { EvaluateCurrentDeputateStats } from '../../../../components/Deputate/EvalutateDeputateStats/EvaluateCurrentDeputateStats';
import { useTimetableBlocksQuery } from '../../../../types/planung-graphql-client-defs';

export const EvaluateCurrentPage = ({ baseSlug }: { baseSlug: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { blockUuid } = useParams();
  const [{ data }] = useTimetableBlocksQuery({ variables: { where: { uuid: blockUuid ?? '' } } });

  return (
    <Page
      title={`${t('routes.deputate.evaluate.current.title')} ${data?.timetableBlocks.map((tb) => tb.timetables.map((tt) => tt.name).join(', ')).join('/')}`}
    >
      <div className={styles.wrap}>
        <IconMenu hasMarginBottom>
          <IconMenuItem
            label={`${t('deputate.menuItems.overview')}`}
            icon={<OverviewIllustration />}
            bubbleType={1}
            onClick={() =>
              navigate('/' + baseSlug + '/' + t('routes.deputate.evaluate.current.slug') + '/' + blockUuid)
            }
            active={location.pathname.endsWith(t('routes.deputate.evaluate.current.slug') + '/' + blockUuid)}
          />

          <IconMenuItem
            label={`${t('deputate.menuItems.subject')}`}
            icon={<SubjectIllustration />}
            bubbleType={5}
            onClick={() => navigate(t('routes.deputate.bySubject.slug'))}
            active={location.pathname.includes(t('routes.deputate.bySubject.slug'))}
          />
          <IconMenuItem
            label={t('pdf.title')}
            icon={<PdfIcon />}
            bubbleType={10}
            onClick={() => navigate(t('routes.deputate.pdf.slug'))}
            active={location.pathname.includes(t('routes.deputate.pdf.slug'))}
          />
        </IconMenu>

        <EvaluateCurrentDeputateStats timetableBlockUuid={blockUuid ?? ''} />
      </div>
      <Suspense fallback={<LazyLoader transparent forceHeight='50vh' />}>
        <Outlet />
      </Suspense>
    </Page>
  );
};
