import { Header, LazyLoader, ScenarioIllustration } from '@bp/ui-components';
import { Page } from '../../../components/Page/Page';
import { useTranslation } from 'react-i18next';
import { IconMenuItem } from '../../../components/IconMenuItem/IconMenuItem';
import IconMenu from '../../../components/IconMenu/IconMenu';
import { Outlet, useNavigate } from 'react-router-dom';
import { Suspense, useMemo } from 'react';
import { useTimetableBlocksQuery } from '../../../types/planung-graphql-client-defs';

export const EvaluatePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [{ data }] = useTimetableBlocksQuery({
    variables: {
      where: {
        timetables_SOME: { versions_SINGLE: { active: true } },
      },
    },
  });

  const memoizedDeputate = useMemo(() => {
    return data?.timetableBlocks.map((block) => {
      return (
        <IconMenuItem
          key={block.uuid}
          label={block.timetables.map((t) => t.name).join(', ')}
          icon={<ScenarioIllustration />}
          bubbleType={18}
          onClick={() => navigate(t('routes.deputate.evaluate.current.slug') + '/' + block.uuid)}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Page title={`${t('deputate.title')} ${t('deputate.evaluate.title').toLowerCase()}`}>
      <IconMenu className={'mb-10'}>
        <IconMenuItem
          label={t('deputate.planTitle')}
          icon={<ScenarioIllustration />}
          bubbleType={1}
          onClick={() => navigate(t('routes.deputate.evaluate.planned.slug'))}
        />
      </IconMenu>
      <Header headline={t('deputate.currentTitle')} subHeadline={t('deputate.currentInfo')}></Header>
      <IconMenu className={'mb-10'}>{memoizedDeputate}</IconMenu>
      <Suspense fallback={<LazyLoader transparent forceHeight='50vh' />}>
        <Outlet />
      </Suspense>
    </Page>
  );
};
