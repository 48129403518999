import { FC, PropsWithChildren, Suspense, useCallback, useMemo, useState } from 'react';
import {
  AddIcon,
  Button,
  Chip,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EditIcon,
  Grid,
  GridColumn,
  GridRow,
  LazyLoader,
  LockIcon,
  Modal,
  Row,
  showToast,
  Table,
  TableColumns,
  Tooltip,
  UnlockIcon,
  useDefaultSelecting,
} from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useDeputateLessons } from '../hooks/useDeputateLessons';
import { LessonCard } from '../../LessonCard/LessonCard';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { DeputateLessonForm } from '../Forms/DeputateLessonForm';
import { LessonTableType } from './TimetableVersionLessonsTable';
import { LessonFormType } from '../types';
import { onlyUnique } from '../../../utils/unique-filter';
import { UsedInVersionsTooltipContent } from '../../TooltipContents/UsedInVersionTooltipContent/UsedInVersionsTooltipContent';
import { EpochForm } from '../Forms/EpochForm';
import { isSubject } from '../../../utils/typeguards';
import { useConfirm } from '../../../hooks/useConfirm';
import { partition } from '../../../utils/arrayFunc';

type LessonOverviewTableProps = PropsWithChildren & {
  lessonsData: LessonTableType[];
  userConfigKey: string;
  showAddButton: boolean;
  contextUuid?: string | undefined;
  defaultInitialValues?: Partial<LessonFormType>;
  isClassContext?: boolean;
};

export const DeputateLessonsTable: FC<LessonOverviewTableProps> = observer(
  ({ lessonsData, userConfigKey, contextUuid, defaultInitialValues, isClassContext }) => {
    const { pimAuthClaims } = useAuthClaims();
    const { t } = useTranslation();
    const schoolYear = useUserConfigContext().selectedSchoolYear;

    const { columnVisibility, saveColumnVisibility } = useHiddenColumns(userConfigKey, {
      'lesson.groups': true,
      classes: false,
      groupNames: false,
    });
    const { sorting, saveSorting } = useColumnsSort(userConfigKey);
    const { rowSelection, onRowSelectionChange } = useDefaultSelecting();

    const [currentLessonUuid, setCurrentLessonUuid] = useState<string | null>(null);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [epochModalOpen, setEpochModalOpen] = useState<boolean>(false);

    const { confirm, ConfirmationDialog } = useConfirm();

    const {
      detachLessons,
      assignLessons,
      duplicateLesson,
      deleteLessons,
      updateCheckboxes: update,
      loadingState,
      queryLessonsData,
    } = useDeputateLessons({
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      schoolYearUuid: schoolYear?.uuid ?? '',
    });

    const updateCheckboxes = useCallback(
      async ({
        type,
        uuids,
        value,
      }: {
        type: 'teachingLoadEnabled' | 'timetableEnabled' | 'elective';
        uuids: string[];
        value: boolean;
      }) => {
        await update({
          type,
          uuids,
          value,
        });
        onRowSelectionChange({});
      },
      [onRowSelectionChange, update],
    );

    const handleAssign = useCallback(
      async (item: LessonTableType, teachers: { uuid: string }[]) => {
        // allowed only with max 1 curriculum Person, so we take the first anyway
        const curriculumPersonProperties = queryLessonsData?.lessons
          .find((lesson) => lesson.uuid === item.uuid)
          ?.curriculum?.personsConnection.edges.map((edge) => edge.properties)[0];

        await assignLessons([item.uuid], teachers, curriculumPersonProperties);
      },
      [queryLessonsData, assignLessons],
    );
    const handleDetach = useCallback(
      async (uuids: string[]) => {
        const response = await detachLessons(uuids);

        if (response.error) {
          showToast(t('lesson.message.detach.error'), { type: 'error' });
        } else {
          showToast(t('lesson.message.detach.success'), { type: 'success' });
        }
      },
      [detachLessons],
    );

    const handleAddLesson = () => {
      setEditModalOpen(true);
    };

    const handleAddEpochClose = () => {
      setCurrentLessonUuid(null);
      setEpochModalOpen(false);
    };

    const handleEditLesson = useCallback(
      (uuid: string | null) => {
        const currentLesson = queryLessonsData?.lessons.find((lesson) => lesson.uuid === uuid);

        if (isSubject(currentLesson?.subject)) {
          setEditModalOpen(true);
        } else {
          setEpochModalOpen(true);
        }
      },
      [queryLessonsData?.lessons],
    );

    const handleClose = () => {
      setCurrentLessonUuid(null);
      setEditModalOpen(false);
    };

    const handleDelete = useCallback(
      async (lessons: LessonTableType[]) => {
        const [canBeDeleted, canNotDelete] = partition(
          lessons,
          (l) => l.editStatus !== 'blocked' && l.editStatus !== 'placedCards',
        );
        const used = lessons.filter((l) => l.editStatus === 'used' || l.editStatus === 'inVersion');

        const message = (
          <Grid>
            <GridRow>
              <GridColumn width={12}>
                {t('lesson.confirm.delete.default', { count: canBeDeleted.length })}
                <ul>
                  {canBeDeleted.map((l) => {
                    return (
                      <li key={l.uuid}>
                        {l.subject} - {l.groupClassNames} - {l.teachers.map((t) => t.name).join(', ') ?? ''}
                      </li>
                    );
                  })}
                </ul>
              </GridColumn>
            </GridRow>
            {used.length > 0 && (
              <GridRow>
                <GridColumn width={12}>
                  {t('lesson.confirm.delete.used', { count: used.length })}
                  <ul>
                    {used.map((l) => {
                      return (
                        <li key={l.uuid}>
                          {l.subject} - {l.groupClassNames} - {l.teachers.map((t) => t.name).join(', ') ?? ''}
                        </li>
                      );
                    })}
                  </ul>
                </GridColumn>
              </GridRow>
            )}
            {canNotDelete.length > 0 && (
              <GridRow>
                <GridColumn width={12}>
                  {t('lesson.confirm.delete.withIgnored', { count: canNotDelete.length })}
                  <ul>
                    {canNotDelete.map((l) => {
                      return (
                        <li key={l.uuid}>
                          {l.subject} - {l.groupClassNames} - {l.teachers.map((t) => t.name).join(', ') ?? ''}
                        </li>
                      );
                    })}
                  </ul>
                </GridColumn>
              </GridRow>
            )}
          </Grid>
        );

        const confirmed = await confirm({ message: message });
        if (confirmed) {
          const response = await deleteLessons(canBeDeleted.map((l) => l.uuid));
          if (response.error) {
            showToast(t('lesson.message.delete.error'), { type: 'error' });
          } else {
            showToast(t('lesson.message.delete.success'), { type: 'success' });
          }
        }
      },
      [confirm, deleteLessons],
    );

    const handleDuplicate = useCallback(
      async (uuids: string[]) => {
        if (loadingState.some(({ uuid, loading }) => loading && uuids.includes(uuid))) {
          return;
        }
        const response = await duplicateLesson(uuids);
        if (response) {
          if (response.some((r) => r.error)) {
            showToast(t('lesson.message.duplicate.error'), { type: 'error' });
          } else {
            showToast(t('lesson.message.duplicate.success'), { type: 'success' });
          }
        } else {
          showToast(t('lesson.message.duplicate.error'), { type: 'error' });
        }
      },
      [duplicateLesson, loadingState],
    );

    const classesOptions = useCreateSelectOptions(queryLessonsData?.classes, 'uuid', 'name');
    const subjectOptions = useCreateSelectOptions(queryLessonsData?.subjects, 'uuid', 'name');
    const teacherOptions = useCreateSelectOptions(queryLessonsData?.people, 'uuid', 'selectName');

    const getChipBgColor = (editStatus: LessonTableType['editStatus']) => {
      switch (editStatus) {
        case 'blocked':
          return 'var(--color-error)';
        case 'placedCards':
        case 'inVersion':
          return 'var(--color-primary-lighter)';
        case 'unused':
        default:
          return 'var(--chip-bg-color)';
      }
    };

    const getChipIconColor = (editStatus: LessonTableType['editStatus']) => {
      switch (editStatus) {
        case 'blocked':
          return 'var(--chip-color-error)';
        case 'placedCards':
          return 'var(--color-warning)';
        case 'inVersion':
        case 'unused':
          return 'var(--chip-color)';
        default:
          return 'var(--chip-color)';
      }
    };

    const getTooltipText = (editStatus: LessonTableType['editStatus']) => {
      switch (editStatus) {
        case 'blocked':
          return t('lesson.blocked');
        case 'placedCards':
          return t('lesson.placedCards');
        case 'inVersion':
          return t('lesson.inVersion');
        default:
          return t('lesson.editable');
      }
    };

    const tableColumns = useMemo((): TableColumns<LessonTableType>[] => {
      return [
        {
          header: t('lesson.table.subject'),
          id: 'subject',
          size: 200,
          accessorKey: 'subject',
        },
        {
          header: t('common.type'),
          id: 'type',
          isMultiline: true,
          enableSorting: true,
          enableGlobalFilter: false,
          cell: ({ row }) => {
            if (row.original.isEpochPlan) {
              return t('common.epochPlan');
            }
            const cardInfos = row.original.cardsInfos;
            return (
              <>
                {cardInfos && cardInfos.defaultCards.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 'var(--spacing-4)',
                      margin: 'var(--spacing-2) 0',
                    }}
                  >
                    {cardInfos.defaultCards.map((group) => {
                      const card = group[0];
                      return (
                        <div key={card.uuid} title={card.typeLabel}>
                          {card.typeLabelShort}
                        </div>
                      );
                    })}
                  </div>
                )}
                {cardInfos && cardInfos.subjectContainerCards.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 'var(--spacing-4)',
                      margin: 'var(--spacing-2) 0',
                    }}
                  >
                    {cardInfos.subjectContainerCards.map((group) => {
                      const card = group[0];
                      return (
                        <div key={card.uuid} title={card.typeLabel}>
                          {card.typeLabelShort}
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            );
          },
          accessorFn: ({ cardsInfos, uuid, isEpochPlan }) => {
            const sum = (cardsInfos?.defaultCards.length ?? 0) + (cardsInfos?.subjectContainerCards.length ?? 0);
            let sortValue = '';
            if (isEpochPlan) {
              sortValue += `0000_${sum}_`;
            } else {
              sortValue += `${sum}_`;
            }

            if (cardsInfos && cardsInfos.defaultCards.length > 0) {
              sortValue += cardsInfos.defaultCards
                .map((dc) => {
                  return dc.map((c) => c.typeLabelShort).join(',');
                })
                .join(',');
            }
            if (cardsInfos && cardsInfos.subjectContainerCards.length > 0) {
              sortValue += cardsInfos.subjectContainerCards
                .map((dc) => {
                  return dc.map((c) => c.typeLabelShort).join(',');
                })
                .join(',');
            }
            return sortValue;
          },
          size: 90,
        },
        {
          header: t('common.hours'),
          id: 'cards',
          size: 150,
          isMultiline: true,
          enableSorting: false,
          enableGlobalFilter: false,
          enableColumnFilter: false,
          canOverflow: true,
          cell: ({ row }) => {
            const cardInfos = row.original.cardsInfos;
            return (
              <>
                {cardInfos && cardInfos.defaultCards.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 'var(--spacing-4)',
                      margin: 'var(--spacing-2) 0',
                    }}
                  >
                    {cardInfos.defaultCards.map((group) => {
                      const card = group[0];
                      return (
                        <div key={card.uuid} title={card.cardLabel}>
                          <LessonCard
                            label={card.cardLabelShort}
                            lessonClasses={card.lesson}
                            count={card.counter}
                            teacherColors={card.colors}
                            duration={card.duration}
                            showHover={false}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
                {cardInfos && cardInfos.subjectContainerCards.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 'var(--spacing-4)',
                      margin: 'var(--spacing-2) 0',
                    }}
                  >
                    {cardInfos.subjectContainerCards.map((group) => {
                      const card = group[0];
                      return (
                        <div key={card.uuid} title={card.cardLabel}>
                          <LessonCard
                            label={card.cardLabelShort}
                            lessonClasses={card.lesson}
                            count={card.counter}
                            teacherColors={card.colors}
                            duration={card.duration}
                            showHover={false}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            );
          },
        },
        {
          header: t('lesson.table.classesGroups'),
          id: 'groupClassNames',
          accessorKey: 'groupClassNames',
          size: 125,
          cell: ({ row }) => {
            return (
              <Tooltip content={row.original.groupClassNames} triggerClass='tooltip-in-table'>
                {row.original.groupClassNames}
              </Tooltip>
            );
          },
        },
        {
          header: t('lesson.table.classes'),
          id: 'classes',
          size: 125,
          cell: ({ row }) => {
            const shortNames = row.original.classes.map((node) => node.name).join(', ');
            return (
              <Tooltip content={shortNames} triggerClass='tooltip-in-table'>
                {shortNames}
              </Tooltip>
            );
          },
          accessorFn: (data) => {
            const shortNames = data.classes.map((node) => node.name);
            return shortNames.join(', ');
          },
        },
        {
          header: t('lesson.table.groups'),
          id: 'groupNames',
          size: 125,
          cell: ({ row }) => {
            const groups = row.original.classes
              .flatMap((node) => node.groups)
              .map((group) => group?.shortName ?? '')
              .filter(onlyUnique)
              .join(', ');
            return (
              <Tooltip content={groups} triggerClass='tooltip-in-table'>
                {groups}
              </Tooltip>
            );
          },
          accessorFn: (data) => {
            const groups = data.classes.flatMap((node) => node.groups);
            return groups.map((group) => group?.shortName ?? '').join(', ');
          },
        },
        {
          header: t('lesson.table.teacher'),
          id: 'teachers',
          cell: ({ row }) => {
            const displayNamesShort = row.original.teachers.map((teacher) => teacher.name).join(', ');
            return (
              <Tooltip content={displayNamesShort} triggerClass='tooltip-in-table'>
                {displayNamesShort}
              </Tooltip>
            );
          },
          accessorFn: (originalRow) => {
            const displayNamesShort = originalRow.teachers.map((teacher) => teacher.name);
            return displayNamesShort.join(', ');
          },
          size: 125,
        },
        {
          header: t('deputate.titleSingluar'),
          id: 'deputate.titleSingluar',
          cell: ({ row }) => {
            const lessonInfo = row.original.lessonInfo;
            const uuid = contextUuid ?? '';

            // relevant deputate
            const td = lessonInfo?.teacherInfos.get(uuid)?.deputat ?? null;
            const cd = lessonInfo?.classInfos.get(uuid)?.deputat ?? null;
            const d = lessonInfo?.deputat ?? 0;
            return (td ?? cd ?? d).toFixed(2);
          },
          accessorFn: (originalRow) => {
            const lessonInfo = originalRow.lessonInfo;
            const uuid = contextUuid ?? '';

            // relevant deputate
            const td = lessonInfo?.teacherInfos.get(uuid)?.deputat ?? 0;
            const cd = lessonInfo?.classInfos.get(uuid)?.deputat ?? 0;
            const d = lessonInfo?.deputat ?? 0;
            return (td + cd + d).toFixed(2).toString();
          },
          size: 50,
        },
        {
          header: t('lesson.basics.isElective') as string,
          id: 'elective',
          accessorKey: 'elective',
          type: 'boolean',
        },
        {
          header: t('lesson.basics.isTeachingLoadEnabled.short'),
          meta: {
            filterName: t('lesson.basics.isTeachingLoadEnabled.full'),
            tooltip: t('lesson.basics.isTeachingLoadEnabled.full'),
          },
          id: 'teachingLoadEnabled',
          accessorKey: 'teachingLoadEnabled',
          type: 'boolean',
        },
        {
          header: t('lesson.basics.isTimetableEnabled.short'),
          meta: {
            filterName: t('lesson.basics.isTimetableEnabled.full'),
            tooltip: t('lesson.basics.isTimetableEnabled.full'),
          },
          id: 'timetableEnabled',
          accessorKey: 'timetableEnabled',
          type: 'boolean',
        },
        {
          header: t('common.editable.short'),
          id: 'editStatus',
          meta: {
            filterName: t('common.editable.full'),
            tooltip: t('common.editable.full'),
          },
          cell: ({ row }) => {
            const currentRowData = queryLessonsData?.lessons.find((lesson) => lesson.uuid === row.original.uuid);

            const status = row.original.editStatus;
            const statusChip = (
              <Chip
                className={'flex'}
                value={
                  row.original.editStatus === 'blocked' ? (
                    <LockIcon color={getChipIconColor(status)} className={'smallest'} />
                  ) : (
                    <UnlockIcon color={getChipIconColor(status)} className={'smallest'} />
                  )
                }
                bgColor={getChipBgColor(status)}
              />
            );
            return (
              <>
                {status === 'unused' || !currentRowData ? (
                  <>{statusChip}</>
                ) : (
                  <Tooltip
                    content={<UsedInVersionsTooltipContent data={currentRowData} usedInText={getTooltipText(status)} />}
                  >
                    {statusChip}
                  </Tooltip>
                )}
              </>
            );
          },
          size: 48,
        },
      ];
    }, [classesOptions, contextUuid, subjectOptions, teacherOptions]);

    const dropdownItems = useCallback(
      (row: Row<LessonTableType>): DropdownMenuItem[] => {
        const qualified = queryLessonsData?.people.filter(
          (person) =>
            person.qualifications.some((q) => q.subject.uuid === row.original.subjectUuid) &&
            !row.original.teacherUuids.includes(person.uuid),
        );
        const teachers = qualified?.length
          ? qualified
          : queryLessonsData?.people.filter((person) => !row.original.teacherUuids.includes(person.uuid));

        if (row.original.isEpochPlan) {
          return [
            {
              label: t('timetableVersion.editCards'),
              onClick: () => {
                setCurrentLessonUuid(row.original.uuid);
                handleEditLesson(row.original.uuid);
              },
            },
            {
              type: 'ruler',
            },
            {
              label: t('common.delete'),
              type: 'error',
              disabled: row.original.editStatus === 'placedCards' || row.original.editStatus === 'blocked',
              onClick: () => {
                handleDelete([row.original]);
              },
            },
          ];
        }

        const moreThanOneTeacherFromCurriculum =
          (queryLessonsData?.lessons.find((lesson) => lesson.uuid === row.original.uuid)?.curriculum?.persons.length ??
            0) > 1;

        return [
          {
            label: t('lesson.edit'),
            onClick: () => {
              setCurrentLessonUuid(row.original.uuid);
              handleEditLesson(row.original.uuid);
            },
          },
          {
            label: t('common.duplicating'),
            onClick: async () => {
              await handleDuplicate([row.original.uuid]);
            },
          },
          {
            label: t('lesson.actions.assignTo'),
            disabled: moreThanOneTeacherFromCurriculum || (row.original.teachers?.length ?? 0) > 1,
            subContent: teachers
              ? teachers.map((teacher) => {
                  return {
                    type: 'default',
                    label: teacher.selectName ?? '',
                    onClick: () => {
                      handleAssign(row.original, [teacher]);
                    },
                  };
                })
              : [],
          },
          {
            label: t('lesson.actions.detach'),
            onClick: () => {
              handleDetach([row.original.uuid]);
            },
          },
          {
            type: 'ruler',
          },
          {
            label: t('lesson.actions.toggle.isElective'),
            type: 'switch',
            value: row.original.elective ?? false,
            onValueChange: async () => {
              await updateCheckboxes({ type: 'elective', uuids: [row.original.uuid], value: !row.original.elective });
            },
          },
          {
            label: t('lesson.basics.isTeachingLoadEnabled.full'),
            type: 'switch',
            value: row.original.teachingLoadEnabled ?? false,
            onValueChange: async () => {
              await updateCheckboxes({
                type: 'teachingLoadEnabled',
                uuids: [row.original.uuid],
                value: !row.original.teachingLoadEnabled,
              });
            },
          },
          {
            label: t('lesson.basics.isTimetableEnabled.full'),
            type: 'switch',
            value: row.original.timetableEnabled ?? false,
            disabled:
              row.original.editStatus === 'placedCards' ||
              row.original.editStatus === 'inVersion' ||
              row.original.editStatus === 'blocked',
            onValueChange: async () => {
              await updateCheckboxes({
                type: 'timetableEnabled',
                uuids: [row.original.uuid],
                value: !row.original.timetableEnabled,
              });
            },
          },
          {
            type: 'ruler',
          },
          {
            label: t('common.delete'),
            type: 'error',
            disabled:
              row.original.editStatus === 'placedCards' ||
              row.original.editStatus === 'blocked' ||
              row.original.editStatus === 'inVersion',
            onClick: () => {
              handleDelete([row.original]);
            },
          },
        ];
      },
      [
        handleAssign,
        handleDelete,
        handleDetach,
        handleDuplicate,
        handleEditLesson,
        queryLessonsData?.lessons,
        queryLessonsData?.people,
        updateCheckboxes,
      ],
    );

    const addDropdownItems: DropdownMenuItem[] = [
      {
        label: t('common.epochPlan'),
        onClick: () => handleEditLesson(currentLessonUuid),
      },
      {
        label: t('lesson.title.singular'),
        onClick: handleAddLesson,
      },
    ];

    const bulkEdit = useCallback((selectedFlatRows: Row<LessonTableType>[]) => {
      const isEpochPlan = selectedFlatRows.every((s) => s.original.isEpochPlan);

      return (
        <DropdownMenu
          data={[
            {
              label: t('lesson.actions.detach') as string,
              type: 'default',
              disabled: isEpochPlan,
              onClick: async () => {
                await handleDetach(
                  selectedFlatRows.filter(({ original }) => !original.isEpochPlan).map(({ original }) => original.uuid),
                );
              },
            },
            {
              label: t('common.delete') as string,
              type: 'error',
              onClick: async () => {
                await handleDelete(selectedFlatRows.map(({ original }) => original));
              },
            },
            {
              label: t('lesson.basics.isElective') as string,
              type: 'default',
              disabled: isEpochPlan,
              subContent: [
                {
                  label: t('common.activateAll') as string,
                  disabled: isEpochPlan,
                  onClick: () => {
                    updateCheckboxes({
                      type: 'elective',
                      uuids: selectedFlatRows
                        .filter(({ original }) => !original.isEpochPlan)
                        .map(({ original }) => original.uuid),
                      value: true,
                    });
                  },
                },
                {
                  label: t('common.deactivateAll') as string,
                  disabled: isEpochPlan,
                  onClick: () => {
                    updateCheckboxes({
                      type: 'elective',
                      uuids: selectedFlatRows
                        .filter(({ original }) => !original.isEpochPlan)
                        .map(({ original }) => original.uuid),
                      value: false,
                    });
                  },
                },
              ],
            },
            {
              label: t('lesson.basics.isTimetableEnabled.full') as string,
              type: 'default',
              disabled: isEpochPlan,
              subContent: [
                {
                  label: t('common.activateAll') as string,
                  disabled: isEpochPlan,
                  onClick: () => {
                    updateCheckboxes({
                      type: 'timetableEnabled',
                      uuids: selectedFlatRows
                        .filter(
                          ({ original }) =>
                            original.editStatus !== 'inVersion' &&
                            original.editStatus !== 'placedCards' &&
                            !original.isEpochPlan,
                        )
                        .map(({ original }) => original.uuid),
                      value: true,
                    });
                  },
                },
                {
                  label: t('common.deactivateAll') as string,
                  disabled: isEpochPlan,
                  onClick: () => {
                    updateCheckboxes({
                      type: 'timetableEnabled',
                      uuids: selectedFlatRows
                        .filter(
                          ({ original }) =>
                            original.editStatus !== 'inVersion' &&
                            original.editStatus !== 'placedCards' &&
                            !original.isEpochPlan,
                        )
                        .map(({ original }) => original.uuid),
                      value: false,
                    });
                  },
                },
              ],
            },
            {
              label: t('lesson.basics.isTeachingLoadEnabled.full') as string,
              type: 'default',
              disabled: isEpochPlan,
              subContent: [
                {
                  label: t('common.activateAll') as string,
                  disabled: isEpochPlan,
                  onClick: () => {
                    updateCheckboxes({
                      type: 'teachingLoadEnabled',
                      uuids: selectedFlatRows
                        .filter(({ original }) => !original.isEpochPlan)
                        .map(({ original }) => original.uuid),
                      value: true,
                    });
                  },
                },
                {
                  label: t('common.deactivateAll') as string,
                  disabled: isEpochPlan,
                  onClick: () => {
                    updateCheckboxes({
                      type: 'teachingLoadEnabled',
                      uuids: selectedFlatRows
                        .filter(({ original }) => !original.isEpochPlan)
                        .map(({ original }) => original.uuid),
                      value: false,
                    });
                  },
                },
              ],
            },
          ]}
        />
      );
    }, []);

    const lastCol = useCallback(
      (row: Row<LessonTableType>) => {
        if (row.original.editStatus === 'blocked') {
          return (
            <Button
              hierarchy={'secondary'}
              icon={<EditIcon className='small' />}
              onClick={() => {
                setCurrentLessonUuid(row.original.uuid);
                handleEditLesson(row.original.uuid);
              }}
            ></Button>
          );
        }

        return (
          <Dropdown
            trigger={
              <Button
                isLoading={loadingState.some(({ loading, uuid }) => uuid === row.original.uuid && loading)}
                hierarchy='secondary'
                icon={<DotsHorizontalIcon className='small' />}
              />
            }
          >
            <DropdownMenu maxHeight='s' data={dropdownItems(row)} />
          </Dropdown>
        );
      },
      [dropdownItems, handleEditLesson, loadingState],
    );

    const actionBarSettings = useMemo(() => {
      return {
        showSelectedRowsCount: true,
        showExpertFilter: true,
        showPrintButton: true,
        showBulkEdit: true,
        showAddButton: !isClassContext,
        addButtonText: t('common.add'),
        extendedActionsRight: isClassContext && (
          <Dropdown
            noPadding
            trigger={
              <Button hierarchy='tertiary' icon={<AddIcon className={'svg-icon'} />}>
                {t('common.add')}
              </Button>
            }
          >
            <DropdownMenu data={addDropdownItems} />
          </Dropdown>
        ),
      };
    }, [addDropdownItems, isClassContext]);

    const printerSettings = useMemo(() => {
      return {
        headline: pimAuthClaims.getProfile()?.organization.name,
        subline: `${t('lesson.title.plural')} - ${t('common.schoolYear')} ${schoolYear?.shortName}`,
        filename: `${t('lesson.title.plural')}_${schoolYear?.shortName}`,
      };
    }, [pimAuthClaims, schoolYear?.shortName]);

    return (
      <>
        <Table<LessonTableType>
          showBorderRadius
          showShadow
          canScroll
          minHeight={1000}
          showVisibility
          loading={loadingState?.some(({ uuid, loading }) => uuid === 'all' && loading)}
          isOnWhite={false}
          printerSettings={printerSettings}
          showActionBar
          rowSelection={rowSelection}
          onRowSelectionChange={onRowSelectionChange}
          showSelect={true}
          showSort={true}
          columnVisibility={columnVisibility}
          onColumnVisibilityChange={saveColumnVisibility}
          sorting={sorting}
          onSortingChange={saveSorting}
          onAddClick={handleAddLesson}
          bulkEditDropdownContent={bulkEdit}
          actionBarSettings={actionBarSettings}
          columns={tableColumns}
          data={lessonsData}
          lastCol={lastCol}
        />
        <Modal
          isOpen={editModalOpen}
          onRequestClose={() => handleClose()}
          title={currentLessonUuid ? t('lesson.edit') : t('lesson.add')}
        >
          {editModalOpen && (
            <Suspense fallback={<LazyLoader />}>
              <DeputateLessonForm
                closeForm={() => handleClose()}
                lessonUuid={currentLessonUuid}
                defaultInitialValues={{ ...defaultInitialValues }}
              />
            </Suspense>
          )}
        </Modal>
        {/*epoch */}
        <Modal isOpen={epochModalOpen} onRequestClose={() => handleAddEpochClose()} title={t('common.epochPlan')}>
          {epochModalOpen && (
            <Suspense fallback={<LazyLoader />}>
              <EpochForm
                closeForm={() => handleAddEpochClose()}
                defaultInitialValues={{ ...defaultInitialValues }}
                lessonUuid={currentLessonUuid}
              />
            </Suspense>
          )}
        </Modal>
        <ConfirmationDialog />
      </>
    );
  },
);
