import { ClassesIllustration, LazyLoader, OverviewIllustration, PdfIcon, SubjectIllustration } from '@bp/ui-components';
import { Page } from '../../../../components/Page/Page';
import { useTranslation } from 'react-i18next';
import { IconMenuItem } from '../../../../components/IconMenuItem/IconMenuItem';
import IconMenu from '../../../../components/IconMenu/IconMenu';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styles from './EvaluatePlannedPage.module.scss';
import { EvaluatePlannedDeputateStats } from '../../../../components/Deputate/EvalutateDeputateStats/EvaluatePlannedDeputateStats';
import { Suspense } from 'react';

export const EvaluatePlannedPage = ({ baseSlug }: { baseSlug: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Page title={t('routes.deputate.evaluate.planned.title')}>
      <div className={styles.wrap}>
        <IconMenu hasMarginBottom>
          <IconMenuItem
            label={t('deputate.menuItems.overview')}
            icon={<OverviewIllustration />}
            bubbleType={1}
            onClick={() => navigate('/' + baseSlug + '/' + t('routes.deputate.evaluate.planned.slug'))}
            active={location.pathname.endsWith(t('routes.deputate.evaluate.planned.slug'))}
          />
          <IconMenuItem
            label={t('deputate.menuItems.classes')}
            icon={<ClassesIllustration />}
            bubbleType={3}
            onClick={() => navigate(t('routes.deputate.byClass.slug'))}
            active={location.pathname.includes(t('routes.deputate.byClass.slug'))}
          />
          <IconMenuItem
            label={t('deputate.menuItems.subject')}
            icon={<SubjectIllustration />}
            bubbleType={5}
            onClick={() => navigate(t('routes.deputate.bySubject.slug'))}
            active={location.pathname.includes(t('routes.deputate.bySubject.slug'))}
          />
          <IconMenuItem
            label={t('pdf.title')}
            icon={<PdfIcon />}
            bubbleType={10}
            onClick={() => navigate(t('routes.deputate.pdf.slug'))}
            active={location.pathname.includes(t('routes.deputate.pdf.slug'))}
          />
        </IconMenu>

        <EvaluatePlannedDeputateStats />
      </div>
      <Suspense fallback={<LazyLoader transparent forceHeight='50vh' />}>
        <Outlet />
      </Suspense>
    </Page>
  );
};
