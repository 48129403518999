import {
  Button,
  ButtonGroup,
  DeleteIcon,
  DropdownMenu,
  DropdownMenuItem,
  EditIcon,
  InvisibleIcon,
  Modal,
  Row,
  showToast,
  Table,
  TableColumns,
  Tooltip,
  useDefaultSelecting,
  VisibleIcon,
} from '@bp/ui-components';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import {
  PersonRole,
  use_ContractsQuery,
  useDeletePeopleMutation,
  useUpdateActivePeopleMutation,
  useUpdatePeopleColorMutation,
} from '../../../types/planung-graphql-client-defs';
import { PersonForm } from '../Forms/PersonForm';
import { observer } from 'mobx-react-lite';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import dayjs from 'dayjs';
import colors from '../../../assets/colors/Colors.json';
import { useIsUsedInLesson } from '../../../hooks/useIsUsedInLesson';
import { useConfirm } from '../../../hooks/useConfirm';
import { partition } from '../../../utils/arrayFunc';
import { UsedInLessonsTooltipContent } from '../../TooltipContents/LessonUsedInTooltipContent/UsedInLessonsTooltipContent';
import { hexToColorOption } from '../../../utils/colorUtils';
import { PersonsListDataType } from '../types';
import { getPersonModalTitle } from '../utils/utils';
import { useRooms } from '../../Rooms/useRooms';
import { UsedColors } from '../../../utils/colorPickerOptions';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';

type PersonsListProps = {
  personsRole: PersonRole;
};

export const TimetablePersonsTable = observer(({ personsRole }: PersonsListProps) => {
  const { pimAuthClaims } = useAuthClaims();

  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const [profile, setProfile] = useState<PersonsListDataType | null>(null);
  const { t } = useTranslation();
  const [modalOpen, setModelOpen] = useState<boolean>(false);
  const [showActive, setShowActive] = useState<boolean>(true);

  const [, deletePerson] = useDeletePeopleMutation();
  const [, updateActive] = useUpdateActivePeopleMutation();
  const [, updateColor] = useUpdatePeopleColorMutation();

  const { confirm, ConfirmationDialog } = useConfirm();
  const check = useIsUsedInLesson();

  const isOther = personsRole === PersonRole.Other;
  const { roomData } = useRooms();

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns(
    'persons-list-timetable' + personsRole.toString(),
    {
      salutation: false,
      birthday: false,
      email: false,
      personalId: false,
      substituteHours: false,
      freelancer: false,
      limitedToFullTime: false,
      isGivenHours: false,
      roomName: false,
    },
  );
  const { sorting, saveSorting } = useColumnsSort('persons-list-timetable');
  const { rowSelection, onRowSelectionChange } = useDefaultSelecting();

  const context = useMemorizedCacheTag('PERSONS');

  const { teacherData: personsData } = useLoadBasicData({ pause: false });

  const [{ data: contractsData }] = use_ContractsQuery({
    pause: !personsData,
  });

  const colorsInUse: Array<UsedColors | null> =
    personsData?.people?.map((sc) => {
      return {
        color: sc.timetableConfig?.color,
        uuid: sc.uuid,
        owner: {
          __typename: 'Person',
          displayName: sc.displayName,
        },
      };
    }) ?? [];

  const persons: PersonsListDataType[] = useMemo(() => {
    const today = dayjs().startOf('day').utc();

    return (
      personsData?.people
        .filter((p) => (showActive ? p.active : true))
        .filter((p) => p.organizationConnection.edges?.some((e) => e.properties.name === personsRole))
        .map((person) => {
          const contracts = contractsData?.contracts.filter((contract) => contract.person.uuid === person.uuid);

          const roomName = roomData?.rooms.find((room) => room.uuid === person.defaultRoom?.uuid)?.name ?? '';

          // get contract that is valid today
          const currentContract = contracts?.find(
            (c) =>
              dayjs(c.validFrom).utc().isSameOrBefore(today, 'day') &&
              (!c.validUntil || dayjs(c.validUntil).utc().isSameOrAfter(today, 'day')),
          );
          const { html, label } = hexToColorOption(person.timetableConfig?.color ?? '');

          return {
            uuid: person.uuid,
            pimProfileUuid: person.pimProfileUuid,
            listName: person.listName ?? '',
            firstName: person.firstName ?? '',
            lastName: person.lastName ?? '',
            shortName: person.shortName ?? '',
            salutation: person.salutation ?? '',
            birthday: person.birthday ?? '',
            email: person.email ?? '',
            personalId: person.personalId ?? '',
            gender: person.gender ?? '',
            substituteHours: currentContract?.substituteHours ?? null,
            hoursWeekly: currentContract?.hoursWeekly ?? null,
            freelancer: currentContract?.freelancer ?? null,
            limitedToFullTime: !!currentContract?.limitedToFullTime,
            isGivenHours: currentContract?.billingMethod === 'GIVEN_HOURS',
            isLoadsMax: currentContract?.billingMethod === 'CONTRACT_IS_MAX',
            isLoadsMin: currentContract?.billingMethod === 'CONTRACT_IS_MIN',
            isExact: currentContract?.billingMethod === 'EXACT',
            validUntil:
              currentContract && currentContract?.validUntil
                ? dayjs(currentContract?.validUntil).format('DD.MM.YYYY')
                : currentContract && currentContract?.validUntil === null
                  ? t('common.unlimited')
                  : '',
            color: {
              color: html,
              colorLabel: label,
            },
            active: person.active ?? false,

            roomName,
          };
        }) ?? []
    );
  }, [personsData?.people, showActive, personsRole, contractsData?.contracts, roomData?.rooms]);

  const tableColumns: TableColumns<PersonsListDataType>[] = useMemo(() => {
    return !isOther
      ? [
          { header: t('persons.name'), accessorKey: 'listName', id: 'listName', size: 200 },
          {
            header: t('common.shortName'),
            accessorKey: 'shortName',
            id: 'shortName',
            size: 150,
          },
          {
            header: t('common.salutation'),
            accessorKey: 'salutation',
            id: 'salutation',
            size: 60,
          },
          {
            header: t('persons.birthDate'),
            accessorKey: 'birthday',
            id: 'birthday',
            size: 80,
            cell({ row }) {
              return row.original.birthday ? dayjs(row.original.birthday).format('DD.MM.YYYY') : '';
            },
          },
          {
            header: t('common.email'),
            accessorKey: 'email',
            id: 'email',
            size: 175,
          },
          {
            header: t('persons.personalId'),
            accessorKey: 'personalId',
            id: 'personalId',
            size: 80,
          },
          {
            header: t('persons.table.substituteHours.name'),
            accessorKey: 'substituteHours',
            id: 'substituteHours',
            type: 'number',
            size: 40,
            meta: {
              filterName: t('persons.table.substituteHours.tooltip'),
              tooltip: t('persons.table.substituteHours.tooltip'),
            },
            enableGlobalFilter: false,
            accessorFn(row) {
              return row.substituteHours?.toString() ?? '---';
            },
            cell({ row }) {
              return row.original.substituteHours?.toString() ?? '';
            },
          },
          {
            header: t('persons.table.freelancer.name'),
            accessorKey: 'freelancer',
            id: 'freelancer',
            type: 'boolean',
            enableGlobalFilter: false,
            meta: {
              filterName: t('persons.table.freelancer.tooltip'),
              tooltip: t('persons.table.freelancer.tooltip'),
            },
          },
          {
            header: t('persons.table.limitedToFullTime.name'),
            accessorKey: 'limitedToFullTime',
            id: 'limitedToFullTime',
            type: 'boolean',
            enableGlobalFilter: false,
            meta: {
              filterName: t('persons.table.limitedToFullTime.tooltip'),
              tooltip: t('persons.table.limitedToFullTime.tooltip'),
            },
          },
          {
            header: t('persons.table.GIVEN_HOURS.name'),
            id: 'isGivenHours',
            accessorKey: 'isGivenHours',
            type: 'boolean',
            enableGlobalFilter: false,
            meta: {
              filterName: t('persons.table.GIVEN_HOURS.tooltip'),
              tooltip: t('persons.table.GIVEN_HOURS.tooltip'),
            },
          },

          {
            header: t('common.color'),
            accessorKey: 'color',
            id: 'color',
            type: 'color',
            size: 50,
          },
          {
            header: t('common.active.short'),
            id: 'active',
            accessorKey: 'active',
            type: 'active',
            meta: {
              filterName: t('common.active.full'),
              tooltip: t('common.active.full'),
            },
          },
          {
            header: t('lesson.table.rooms'),
            id: 'roomName',
            accessorKey: 'roomName',
            size: 300,
          },
        ]
      : [
          { header: t('persons.name'), accessorKey: 'listName', id: 'listName', size: 200, canExpand: true },
          {
            header: t('common.shortName'),
            accessorKey: 'shortName',
            id: 'shortName',
            size: 175,
          },
          {
            header: t('common.salutation'),
            accessorKey: 'salutation',
            id: 'salutation',
          },
          {
            header: t('persons.birthDate'),
            accessorKey: 'birthday',
            id: 'birthday',
            cell({ row }) {
              return row.original.birthday ? dayjs(row.original.birthday).format('DD.MM.YYYY') : '';
            },
          },
          {
            header: t('common.email'),
            accessorKey: 'email',
            id: 'email',
            size: 175,
          },
          {
            header: t('persons.personalId'),
            accessorKey: 'personalId',
            id: 'personalId',
            size: 80,
          },
          {
            header: t('common.color'),
            accessorKey: 'color',
            id: 'color',
            type: 'color',
          },
          {
            header: t('common.active.short'),
            id: 'active',
            accessorKey: 'active',
            type: 'active',
            meta: {
              filterName: t('common.active.full'),
              tooltip: t('common.active.full'),
            },
          },
          {
            header: t('lesson.table.rooms'),
            id: 'roomName',
            accessorKey: 'roomName',
            size: 300,
          },
        ];
  }, [isOther, t]);

  const handleDelete = useCallback(
    async (rows: Row<PersonsListDataType>[]) => {
      const [used, notUsed] = partition(rows, (row) => check(row.original.uuid, 'teacher').isUsed);
      const uuids = notUsed.map((r) => r.original.uuid);
      await confirm({
        message: (
          <div>
            <div>{t('persons.deleteConfirm', { count: uuids.length })}</div>
            <ul>
              {notUsed.map((s) => {
                return <li key={s.original.uuid}>{s.original.listName}</li>;
              })}
            </ul>
            {used && (
              <>
                <div>{t('persons.canNotDelete', { count: used.length })}</div>
                <ul>
                  {used.map((s) => {
                    return <li key={s.original.uuid}>{s.original.listName}</li>;
                  })}
                </ul>
              </>
            )}
          </div>
        ),
        onConfirm: async () => {
          if (uuids) {
            const response = await deletePerson({ uuids: uuids });
            if (response.error) {
              showToast(t('persons.delete.error'), { type: 'error' });
            } else {
              showToast(t('persons.delete.success'), { type: 'success' });
            }
          }
          onRowSelectionChange({});
        },
      });
    },
    [check, confirm, deletePerson, onRowSelectionChange, t],
  );

  const handleEdit = (row: Row<PersonsListDataType>) => {
    setProfile(row.original ?? null);
    setModelOpen(true);
  };

  const onModalClose = () => {
    setProfile(null);
    setModelOpen(false);
  };

  const createBulkEditItems = useCallback(
    (rows: Row<PersonsListDataType>[]): DropdownMenuItem[] => {
      const addColors = (uuids: string[]) => {
        uuids.forEach((uuid) => {
          const color = colors[Math.floor(Math.random() * colors.length)];
          updateColor({ color: color.html, uuid: uuid }, context);
        });
      };
      const updateCheckboxes = async ({ type, uuids, value }: { type: 'active'; uuids: string[]; value: boolean }) => {
        if (type === 'active') {
          await updateActive({ uuids: uuids, active: value }, context);
        }
      };
      return [
        ...(!isOther
          ? [
              {
                label: t('common.add-random-color'),
                type: 'default',
                onClick: async () => {
                  await addColors(rows.map(({ original }) => original.uuid));
                },
              } as DropdownMenuItem,
              {
                type: 'ruler',
              } as DropdownMenuItem,
              {
                label: t('persons.table.freelancer.tooltip'),
                subContent: [
                  {
                    label: t('common.activateAll'),
                    onClick: () => {
                      updateCheckboxes({
                        type: 'active',
                        uuids: rows.map(({ original }) => original.uuid),
                        value: true,
                      });
                    },
                  },
                  {
                    label: t('common.deactivateAll'),
                    onClick: () => {
                      updateCheckboxes({
                        type: 'active',
                        uuids: rows.map(({ original }) => original.uuid),
                        value: false,
                      });
                    },
                  },
                ],
              },
            ]
          : []),
        {
          label: t('common.active.full'),
          type: 'default',
          subContent: [
            {
              label: t('common.activateAll'),
              onClick: () => {
                updateCheckboxes({
                  type: 'active',
                  uuids: rows.map(({ original }) => original.uuid),
                  value: true,
                });
              },
            },
            {
              label: t('common.deactivateAll'),
              onClick: () => {
                updateCheckboxes({
                  type: 'active',
                  uuids: rows.map(({ original }) => original.uuid),
                  value: false,
                });
              },
            },
          ],
        },
        {
          type: 'ruler',
        },
        {
          label: t('common.delete'),
          type: 'error',
          onClick: async () => {
            await handleDelete(rows);
          },
        },
      ];
    },
    [context, handleDelete, isOther, t, updateActive, updateColor],
  );

  return (
    <>
      <Table<PersonsListDataType>
        showBorderRadius
        showShadow
        canScroll
        minHeight={600}
        breakpoint={null}
        showSelect
        showVisibility
        isOnWhite={false}
        rowSelection={rowSelection}
        onRowSelectionChange={onRowSelectionChange}
        onColumnVisibilityChange={saveColumnVisibility}
        bulkEditDropdownContent={(rows) => {
          return <DropdownMenu data={createBulkEditItems(rows)} />;
        }}
        columnVisibility={columnVisibility}
        onSortingChange={saveSorting}
        showSort
        sorting={sorting}
        columns={tableColumns}
        data={persons}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('persons.title.plural')} - ${t('common.schoolYear')} ${currentSchoolYear?.shortName}`,
          filename: `${t('persons.title.plural')}_${currentSchoolYear?.shortName}`,
        }}
        showActionBar
        actionBarSettings={{
          showExpertFilter: true,
          showAddButton: true,
          showPrintButton: true,
          showBulkEdit: true,
          extendedActionsRight: (
            <Button
              hierarchy={'tertiary'}
              className='ml-4'
              onClick={() => {
                setShowActive(!showActive);
              }}
              icon={!showActive ? <VisibleIcon /> : <InvisibleIcon />}
            >
              {showActive ? t('persons.hideActive') : t('persons.showActive')}
            </Button>
          ),
        }}
        onAddClick={() => {
          setProfile(null);
          setModelOpen(true);
        }}
        lastColWidth='80px'
        lastCol={(personRow) => {
          const used = check(personRow.original.uuid, 'teacher');

          const deleteButton = (
            <Button
              disabled={used.isUsed}
              onClick={() => handleDelete([personRow])}
              hierarchy='secondary'
              type='button'
              icon={<DeleteIcon className='small' />}
            />
          );
          return (
            <ButtonGroup>
              <Button
                hierarchy='secondary'
                type='button'
                icon={<EditIcon className='small' />}
                onClick={() => {
                  handleEdit(personRow);
                }}
              />
              {used.isUsed ? (
                <Tooltip
                  content={
                    <UsedInLessonsTooltipContent lessons={used.lessons} usedInText={t('persons.usedInLesson')} />
                  }
                >
                  {deleteButton}
                </Tooltip>
              ) : (
                deleteButton
              )}
            </ButtonGroup>
          );
        }}
      />
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onRequestClose={onModalClose}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          title={getPersonModalTitle(profile, isOther)}
          subtitle={profile ? profile.personalId ?? '' : ''}
        >
          <PersonForm
            personsRole={personsRole}
            personUuid={profile?.uuid}
            closeForm={onModalClose}
            htmlColorsInUse={colorsInUse}
          />
        </Modal>
      )}
      <ConfirmationDialog />
    </>
  );
});
